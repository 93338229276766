import { useRouter } from "next/router";
import Tealium from "@4tn/webx-analytics";
import SearchResultItems from "@navigation/SearchModal/components/SearchModalResult/SearchResultItems";
import { SearchTabs, searchTabValues } from "@navigation/SearchModal/components/SearchModalTabBar/SearchModalTabBar";
import InfiniteCollectionSkeleton from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import { getRouteSlug } from "@utils/common/getPageCategory";
import usePaginatedFetch from "@utils/common/usePaginatedFetch";
import { fetchLinks } from "@utils/pageContent/fetchData";
import ErrorCollection from "./ErrorCollection";

const trackClick = (position: number) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.SEARCH_QUERY_404,
    event_category: TEALIUM_EVENT_CATEGORY.SEARCH,
    event_label: TEALIUM_EVENT_LABEL.SEARCH_RESULTS,
    event_value: position,
  });
};

const trackSearchValue = (searchValue: string) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.SEARCH_QUERY_404,
    event_category: TEALIUM_EVENT_CATEGORY.SEARCH,
    event_label: searchValue,
  });
};

const limit = 8;

const NotFoundSuggestions: React.FC = () => {
  const { asPath } = useRouter();
  const tabContentType = searchTabValues[SearchTabs.ARTICLES].contentType;

  const {
    contentApiEndpoint,
    notFoundPage: { searchResultTitle, collectionUrl },
  } = useHostConfig();

  const { items, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, error } = usePaginatedFetch<PageLink>({
    queryKey: ["route-slug-based-search", asPath],
    queryFn: ({ pageParam = 1 }) => {
      const searchValue = getRouteSlug(asPath)?.replace(/-/g, " ") || "";
      if (!searchValue)
        return {
          items: [],
          nextPage: limit,
          totalResults: 0,
        };

      trackSearchValue(searchValue);
      return fetchLinks({
        endpoint: `${contentApiEndpoint}/search/` + searchValue,
        page: pageParam,
        contentType: tabContentType,
        limit,
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  if (isLoading && !items?.length)
    return <InfiniteCollectionSkeleton title={searchResultTitle} limit={limit} showCategory showAllText={""} />;

  if (collectionUrl && items?.length === 0 && !isLoading) {
    return <ErrorCollection collectionUrl={collectionUrl} limit={limit} />;
  }

  return (
    <SearchResultItems
      title={searchResultTitle}
      items={items}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={!!hasNextPage}
      isLoading={isLoading}
      limit={limit}
      fetchNextPage={fetchNextPage}
      trackClick={trackClick}
    />
  );
};

export default NotFoundSuggestions;

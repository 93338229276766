import Router from "next/router";
import { QueryParams } from "@constants/consts";

export default function updateSearchParams(searchQueryParamValue: string | null, tabQueryParamValue?: string) {
  const searchParams = new URLSearchParams(location.search);
  const [pathname] = Router.asPath.split("?"); // using asPath instead of pathname so that the search modal can be opened from dynamic pages as well

  if (searchQueryParamValue !== null) {
    // In case we actually have a search param (non falsy string value is passed as param) or if we are opening the modal (empty string is passed as param)
    searchParams.set(QueryParams.SEARCH_KEYWORD, searchQueryParamValue);
    if (tabQueryParamValue) searchParams.set(QueryParams.SEARCH_TAB, tabQueryParamValue);
    else searchParams.delete(QueryParams.SEARCH_TAB);

    const url = `${pathname}?${searchParams.toString()}`;
    Router.push(url, undefined, { shallow: true });
  } else {
    searchParams.delete(QueryParams.SEARCH_KEYWORD);
    searchParams.delete(QueryParams.SEARCH_TAB);
    const url = pathname + (searchParams.size > 0 ? `?${searchParams.toString()}` : "");
    // In case we are closing the search modal (null is passed as param)
    Router.push(url, undefined, { shallow: true });
  }
}

import Link from "next/link";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./PortraitCollection.styled";

const PortraitCollection: React.FC<LinkList> = ({ title, items, showAllText, showAllUrl }) => (
  <Styled.PortraitCollectionContainer>
    <Styled.PortraitCollectionHeader>
      <Styled.PortraitCollectionTitle>{title}</Styled.PortraitCollectionTitle>
      {showAllUrl && (
        <Styled.PortraitCollectionShowAll>
          <Link href={showAllUrl}>{showAllText}</Link>
        </Styled.PortraitCollectionShowAll>
      )}
    </Styled.PortraitCollectionHeader>
    <Styled.StyledSwimlane hasHoverEffect>
      {items.map((item, index) => (
        <Styled.Portrait
          key={item.id}
          onClick={() =>
            trackContentClick({
              position: index + 1,
              label: title,
              ...item,
            })
          }
          data-testid="portrait"
        >
          <Link target={item.target} href={item.url}>
            <Styled.AspectRatio aspectRatio="2:3">
              <Styled.PortraitImage
                alt={item.title}
                src={item.imageUrl}
                fill
                sizes="(max-width: 900px) 50vw, (max-width: 1200px) 30vw, 20vw"
              />
            </Styled.AspectRatio>
          </Link>
        </Styled.Portrait>
      ))}
    </Styled.StyledSwimlane>
  </Styled.PortraitCollectionContainer>
);

export default PortraitCollection;

export default class EventBus {
  publishedEventRegistry: EventRegistry<keyof WebXEventParams> = {};
  initialized: boolean = false;

  constructor({ que }: Window["eventBus"]) {
    if (que) {
      que.on.forEach(([eventName, handler, useLatest = false]) => {
        this.on(eventName, handler, useLatest);
      });
      que.dispatch.forEach(([eventName, payload]) => {
        this.dispatch(eventName, payload);
      });
    }
    this.initialized = true;
  }

  dispatch<WebXEvent extends keyof WebXEventParams>(eventName: WebXEvent, data: WebXEventParams[WebXEvent]) {
    const registeredEvent = this.publishedEventRegistry[eventName];

    this.publishedEventRegistry = {
      ...this.publishedEventRegistry,
      [eventName]: {
        handlers: [],
        ...registeredEvent,
        name: eventName,
        data,
      },
    };
    if (registeredEvent) {
      registeredEvent.handlers.forEach((handler) => {
        handler(data);
      });
    }
    if (this.publishedEventRegistry.all) {
      this.publishedEventRegistry.all.handlers.forEach((handler) => {
        handler({ key: eventName, event: data });
      });
    }
  }

  on<WebXEvent extends keyof WebXEventParams>(
    eventName: WebXEvent,
    handler: (data: WebXEventParams[WebXEvent]) => void,
    useLatest: boolean = false
  ): WebXEventParams[WebXEvent] | null {
    const registeredEvent = this.publishedEventRegistry[eventName];

    if (registeredEvent?.handlers.some((registeredHandler) => registeredHandler === handler)) {
      // handler already added
      return registeredEvent?.data || null;
    }

    this.publishedEventRegistry = {
      ...this.publishedEventRegistry,
      [eventName]: {
        ...registeredEvent,
        name: eventName,
        handlers: [...(registeredEvent?.handlers || []), handler],
      },
    };

    if (useLatest && registeredEvent?.data) {
      handler(registeredEvent.data);
    }

    return registeredEvent?.data || null;
  }

  off<WebXEvent extends keyof WebXEventParams>(
    eventName: WebXEvent,
    handler: (data: WebXEventParams[WebXEvent]) => void
  ) {
    const registeredEvent = this.publishedEventRegistry[eventName];
    if (registeredEvent) {
      registeredEvent.handlers = registeredEvent.handlers.filter((registeredHandler) => registeredHandler !== handler);
    }
  }
}

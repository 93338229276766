import React from "react";
import Link from "next/link";
import { Button } from "@common/Buttons";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { RootNode } from "@typings/richText";
import RichText from "../RichText";
import * as Styled from "./TextFrame.styled";

interface TextFrameProps {
  title: string;
  text: RootNode;
  target?: "_blank" | "_self";
  buttonText?: string;
  url?: string;
  showTitle?: boolean;
}

const TextFrame: React.FC<TextFrameProps> = ({ title, text, target, buttonText, url, showTitle }) => {
  return (
    <Styled.Wrapper>
      {showTitle && <Styled.Title>{title}</Styled.Title>}
      <RichText richText={text} />
      {buttonText && url && (
        <Button component={Link} variant={VARIANT.PRIMARY} size={BUTTON_SIZE.MEDIUM} target={target} href={url}>
          {buttonText}
        </Button>
      )}
    </Styled.Wrapper>
  );
};

export default TextFrame;

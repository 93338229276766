import jumpBySeconds from "./jumpBySeconds";

const SECONDS_TO_SKIP = 10;

export default function addControlButtons(playerInstance: jwInstance): void {
  const playerContainer = playerInstance.getContainer();

  if (!playerContainer || playerContainer.querySelector(".jw-display-icon-forwards")) return;

  const rewindIcon = playerContainer.querySelector(".jw-display-icon-rewind");
  rewindIcon?.addEventListener("click", () => {
    jumpBySeconds(-SECONDS_TO_SKIP, playerInstance);
  });

  const nextButton = playerContainer.querySelector(".jw-display-icon-next") as HTMLElement;
  if (nextButton) nextButton.style.display = "none";

  const forwardsContainer = document.createElement("div");
  forwardsContainer.className = "jw-display-icon-container jw-display-icon-forwards jw-reset";

  const forwardsButton = document.createElement("div");
  forwardsButton.className = "jw-icon jw-icon-forwards jw-button-color jw-reset";
  forwardsButton.role = "button";
  forwardsButton.tabIndex = 0;
  forwardsButton.ariaLabel = "Forward 10 Seconds";

  forwardsContainer.addEventListener("click", () => {
    jumpBySeconds(SECONDS_TO_SKIP, playerInstance);
  });

  forwardsContainer.appendChild(forwardsButton);

  playerContainer.querySelector(".jw-display-controls")?.appendChild(forwardsContainer);
}

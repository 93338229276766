import { colors, fonts } from "./cssVariables";

export enum BUTTON_TYPE {
  DEFAULT = "default",
  ICON = "icon",
}

export enum BUTTON_SIZE {
  EXTRA_SMALL = "xs",
  SMALL = "s",
  MEDIUM = "md",
  LARGE = "lg",
}

export enum VARIANT {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  OUTLINE = "outline",
  TEXT = "text",
  TERTIARY = "tertiary",
}

export enum ELEMENT_TYPE {
  BUTTON = "button",
  A = "a",
}

export const buttonWrapperSizes: Record<BUTTON_SIZE, ButtonSizeStyles> = {
  xs: {
    height: 22,
    width: 22,
    padding: "6px",
  },
  s: {
    height: 28,
    width: 28,
    padding: "12px 8px",
  },
  md: {
    height: 40,
    width: 40,
    padding: "12px",
  },
  lg: {
    height: 52,
    width: 52,
    padding: "12px 16px",
  },
};

export const buttonTextSizes: Record<BUTTON_SIZE, ButtonSizeStyles> = {
  xs: {},
  s: {
    padding: "0 4px",
    font: fonts.buttonSmall,
  },
  md: {
    padding: "0 8px",
    font: fonts.buttonMedium,
  },
  lg: {
    padding: "0 8px",
    font: fonts.buttonLarge,
  },
};

export const iconSizes: Record<BUTTON_SIZE, ButtonSizeStyles> = {
  xs: {
    height: 10,
    width: 10,
  },
  s: {
    height: 16,
    width: 16,
  },
  md: {
    height: 20,
    width: 20,
  },
  lg: {
    height: 24,
    width: 24,
  },
};

export const variantColors: Record<VARIANT, ButtonColors> = {
  primary: {
    backgroundColor: colors.button.primaryBackgroundColor,
    contentColor: colors.button.primaryTextColor,
    hoverColor: colors.button.hoverPrimaryBackgroundColor,
    hoverContentColor: colors.button.primaryTextColor,
    activeColor: colors.button.pressedPrimaryBackgroundColor,
    activeContentColor: colors.button.primaryTextColor,
    disabledBackgroundColor: colors.neutral10,
    disabledContentColor: colors.neutral20,
  },
  secondary: {
    backgroundColor: colors.button.secondaryBackgroundColor,
    contentColor: colors.button.secondaryTextColor,
    hoverColor: colors.button.hoverSecondaryBackgroundColor,
    hoverContentColor: colors.button.secondaryTextColor,
    activeColor: colors.button.pressedSecondaryBackgroundColor,
    activeContentColor: colors.button.secondaryTextColor,
    disabledBackgroundColor: colors.neutral10,
    disabledContentColor: colors.neutral20,
  },
  outline: {
    backgroundColor: "transparent",
    contentColor: colors.primary,
    hoverColor: colors.primary120,
    hoverContentColor: colors.neutral0,
    activeColor: colors.primary50,
    activeContentColor: colors.neutral0,
    disabledBackgroundColor: "transparent",
    disabledContentColor: colors.neutral20,
    borderColor: colors.primary,
    borderColorActive: colors.primary50,
    borderColorHover: colors.primary120,
    borderColorDisabled: colors.neutral20,
  },
  text: {
    backgroundColor: "transparent",
    contentColor: colors.button.textColor,
    hoverColor: colors.button.hoverPrimaryBackgroundColor,
    hoverContentColor: colors.button.hoverPrimaryTextColor,
    activeColor: colors.button.pressedPrimaryBackgroundColor,
    activeContentColor: colors.button.pressedPrimaryTextColor,
    disabledBackgroundColor: "transparent",
    disabledContentColor: colors.neutral20,
  },
  tertiary: {
    backgroundColor: colors.neutral0,
    contentColor: colors.primary,
    hoverColor: colors.primary120,
    hoverContentColor: colors.neutral0,
    activeColor: colors.primary50,
    activeContentColor: colors.neutral0,
    disabledBackgroundColor: colors.neutral0,
    disabledContentColor: colors.neutral20,
  },
};

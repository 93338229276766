import React from "react";
import useSearchValues from "@navigation/SearchModal/useSearchValues";
import { useQuery } from "@tanstack/react-query";
import SquareCollection from "@pageContent/SquareCollection";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import getHeaderAndBodyText from "@utils/search/getHeaderAndBodyText";
import { SearchTabs } from "../SearchModalTabBar/SearchModalTabBar";
import * as Styled from "./NoSearchResult.styled";

const noSearchResultQueryKey = "no-search-result";

interface NoSearchResultProps {
  currentTab: SearchTabs;
}

const NoSearchResult: React.FC<NoSearchResultProps> = ({ currentTab }) => {
  const { noSearchResults } = useHostConfig();
  const { searchValue } = useSearchValues();
  const collectionUrl = noSearchResults?.collectionUrl;

  const { data: collection, error } = useQuery<LinkList>({
    queryKey: [noSearchResultQueryKey],
    queryFn: () => fetchByEndpoint<LinkList>(collectionUrl),
    enabled: !!collectionUrl,
  });

  useNoticeError(error, {
    queryKey: noSearchResultQueryKey,
    searchValue,
    collectionUrl,
  });

  if (!noSearchResults) return;

  const { headerText, bodyText, bodyTextSuggestion } = noSearchResults;

  const [noResultHeaderText, noResultBodyText] = getHeaderAndBodyText(currentTab, headerText, bodyText);

  return (
    <React.Fragment>
      <Styled.TextContainer hasTopMargin={currentTab === SearchTabs.ALL}>
        <Styled.Title>{noResultHeaderText}</Styled.Title>
        <Styled.BodyTextWrapper>
          <Styled.BodyText>
            {noResultBodyText} <b>&lsquo;{searchValue}&rsquo;</b>.
          </Styled.BodyText>
          <br />
          <Styled.BodyText>{bodyTextSuggestion}</Styled.BodyText>
        </Styled.BodyTextWrapper>
      </Styled.TextContainer>
      {collection && <SquareCollection title={collection.title} items={collection.items} />}
    </React.Fragment>
  );
};

export default NoSearchResult;

import React, { useMemo } from "react";
import Link from "next/link";
import { useQuery } from "@tanstack/react-query";
import { addDays, startOfToday } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { TIMEZONE } from "@constants/date";
import { fetchRadioSchedule } from "@utils/audio/audioApi";
import { useNoticeError } from "@utils/common/newRelic";
import * as Styled from "./RadioSchedule.styled";
import RadioScheduleItem from "./RadioScheduleItem/RadioScheduleItem";
import RadioScheduleSkeleton from "./RadioScheduleSkeleton";

const startDate = utcToZonedTime(startOfToday(), TIMEZONE);
const endDate = addDays(startDate, 7);

const RadioSchedule: React.FC<RadioSchedule> = ({ stationSlug, title, showAllText, showAllUrl }) => {
  const radioScheduleQueryKey = ["radio-schedule", stationSlug, startDate, endDate];

  const {
    isLoading,
    data: items,
    error,
  } = useQuery({
    queryKey: radioScheduleQueryKey,
    queryFn: () => fetchRadioSchedule(startDate, endDate, stationSlug),
    enabled: !!stationSlug,
  });

  useNoticeError(error, { queryKey: radioScheduleQueryKey.join(",") });

  const scrollToItemIndex = useMemo(() => items?.findIndex((item) => item.isLive) || 0, [items]);

  if (!isLoading && !items) return null;

  if (isLoading || !items?.length) return <RadioScheduleSkeleton hasShowAllText={!!showAllText} />;

  return (
    <Styled.RadioScheduleContainer>
      <Styled.RadioScheduleHeader>
        <Styled.RadioScheduleTitle>{title}</Styled.RadioScheduleTitle>
        {showAllUrl && (
          <Styled.RadioScheduleShowAll>
            <Link href={showAllUrl}>{showAllText}</Link>
          </Styled.RadioScheduleShowAll>
        )}
      </Styled.RadioScheduleHeader>
      <Styled.ScheduleSwimlane scrollToItemIndex={scrollToItemIndex + 1} hasHoverEffect={true} alignScrolledItem>
        {items.map((schedule, index) => (
          <React.Fragment key={schedule.id}>
            {(!items[index - 1] || items[index - 1].dayShort !== schedule.dayShort) && (
              <Styled.DayDivider>
                <span>{schedule.dayShort}</span>
              </Styled.DayDivider>
            )}
            <RadioScheduleItem {...schedule} position={index + 1} />
          </React.Fragment>
        ))}
      </Styled.ScheduleSwimlane>
    </Styled.RadioScheduleContainer>
  );
};

export default RadioSchedule;

import { keyframes, styled } from "@mui/material";
import { TopBarContainer } from "@navigation/TopNavigation/TopBar/TopBar.styled";
import { AnimatedArrowIcon, CrossIcon, SearchIcon } from "@common/Icons";
import { maxPageContentWidth } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";

export const SearchModalTopBarContainer = styled(TopBarContainer)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    overflow: "auto",
    scrollbarGutter: "stable",
  },
}));

export const SearchModalTopBarContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  height: "100%",
  maxWidth: maxPageContentWidth,
  margin: "0 auto",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up("lg")]: {
    justifyContent: "normal",
    padding: theme.spacing(0, 24),
  },
}));

export const SearchModalBackIcon = styled(AnimatedArrowIcon)(() => ({
  cursor: "pointer",
  svg: {
    display: "block",
    color: colors.neutral0,
    width: 40,
    height: 40,
  },
}));

const searchModalInputAnimation = keyframes`
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  `;

const searchModalInputAnimationLg = keyframes`
    0% {
      left: "calc(50% - 200px)";
      width: 400;
    }
    100% {
      left: 0;
      width: 100%;
    }
  `;

export const SearchModalInputContainer = styled("div")<{ isModalOpen: boolean }>(({ theme, isModalOpen }) => ({
  cursor: "text",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: 40,
  borderRadius: 100,
  padding: theme.spacing(2, 4, 2, 3),
  gap: theme.spacing(3),
  backgroundColor: "rgba(255,255,255, 0.2)", // We use rgba format here in order to apply opacity exclusively to the background color
  overflow: "hidden",

  ...(isModalOpen && {
    animation: `${searchModalInputAnimation} 300ms ease-in forwards`,
  }),

  [theme.breakpoints.up("lg")]: {
    width: 400,
    position: "relative",
    left: "calc(50% - 200px)",

    ...(isModalOpen && {
      animation: `${searchModalInputAnimationLg} 300ms ease-in forwards`,
    }),
  },
}));

export const SearchModalSearchIcon = styled(SearchIcon)(() => ({
  cursor: "text",
  svg: {
    display: "block",
    color: colors.neutral0,
    width: 24,
    height: 24,
  },
}));

export const SearchModalInput = styled("input")(() => ({
  display: "flex",
  flex: 1,
  backgroundColor: "transparent",
  color: colors.neutral0,
  font: fonts.bodyMedium,
  border: "none",
  outline: "none",
  overflow: "hidden",

  "::placeholder": {
    color: colors.neutral0,
    opacity: 1, // Firefox adds a lower opacity to the placeholder
  },
}));

export const SearchModalClearInputIcon = styled(CrossIcon)(() => ({
  cursor: "pointer",
  svg: {
    display: "block",
    color: colors.neutral0,
    width: 16,
    height: 16,
  },
}));

import { ReactNode } from "react";
import { Skeleton, styled } from "@mui/material";
import { colors } from "@constants/cssVariables";

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: colors.neutral10,
  borderRadius: 26,
  width: "100%",
  maxWidth: "none",
  maxHeight: 52,

  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const ButtonSkeleton: React.FC<{ children: ReactNode }> = ({ children }) => (
  <StyledSkeleton variant="rounded">{children}</StyledSkeleton>
);

export default ButtonSkeleton;

import Link from "next/link";
import { styled } from "@mui/material";
import Icon from "@common/Icons";
import { maxPageContentWidth } from "@constants/consts";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const TabBarContainer = styled("div", { shouldForwardProp: (prop) => prop !== "isTabBarHidden" })<{
  isTabBarHidden?: boolean;
}>(({ isTabBarHidden, theme }) => ({
  position: "absolute",
  zIndex: zIndex.appBar - 1,
  height: 56,
  top: 0,
  width: "100%",
  backgroundColor: colors.tabBar,
  marginTop: theme.spacing(14),
  paddingRight: 0,
  transition: "transform 150ms ease-in",
  transform: isTabBarHidden ? "translateY(-112px)" : "none",

  [theme.breakpoints.up("md")]: {
    transform: isTabBarHidden ? "translateY(-120px)" : "none",

    marginTop: theme.spacing(16),
  },
}));

export const ItemsGradientContainer = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  margin: "0 auto",
  position: "relative",
  maxWidth: maxPageContentWidth,

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-start",
    padding: theme.spacing(0, 24),
  },
}));

export const NavigationItem = styled(Link)(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  color: colors.neutral0,
  textDecoration: "none",
  font: fonts.tab,
  whiteSpace: "nowrap",
  borderBottom: "3px solid transparent",
  transition: "all 0.3s",
  cursor: "pointer",

  "&[aria-current=true]": {
    borderBottom: "3px solid white",
    fontWeight: "bold",
  },

  "&:after": {
    content: 'attr(data-text) / "";',
    height: 0,
    visibility: "hidden",
    overflow: "hidden",
    userSelect: "none",
    pointerEvents: "none",
    fontWeight: "bold",
  },

  [theme.breakpoints.up("lg")]: {
    "&:hover": {
      borderBottom: "3px solid white",
    },
  },

  [theme.breakpoints.down("md")]: {
    [":first-of-type"]: {
      marginLeft: theme.spacing(4),
    },
  },
}));

export const ItemsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  color: colors.neutral0,
  justifyContent: "flex-start",
  marginLeft: 0,
  gap: theme.spacing(6),
  overflowX: "auto",
  [`::-webkit-scrollbar`]: {
    display: "none",
  },
  MsOverflowStyle: "none", // Edge
  scrollbarWidth: "none", // Firefox

  "a:first-of-type": {
    paddingLeft: 0,
  },

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(8),
  },

  [theme.breakpoints.down("md")]: {
    paddingRight: theme.spacing(4),
  },
}));

export const GradientDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100%",
  zIndex: zIndex.gradient,
  top: 0,
  width: 80,

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const LeftGradient = styled(GradientDiv)(({ theme }) => ({
  left: 0,
  background: `${gradients.fadePrimary90Left} 100% 100%;`,

  [theme.breakpoints.up("lg")]: {
    left: 96,
  },
}));

export const RightGradient = styled(GradientDiv)(({ theme }) => ({
  right: 0,
  background: `${gradients.fadePrimary90Right} 100% 100%;`,

  [theme.breakpoints.up("lg")]: {
    right: 96,
  },
}));

const Arrow = styled(Icon, { shouldForwardProp: (prop) => prop !== "isShown" })<{
  isShown: boolean;
}>(({ theme, isShown }) => ({
  zIndex: zIndex.appBar,
  color: colors.neutral0,
  position: "absolute",
  top: 14,
  cursor: "pointer",
  height: 28,
  width: 28,
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  visibility: isShown ? "visible" : "hidden",
  border: `2px solid ${colors.neutral0}`,
  borderRadius: "26px",

  "div:first-of-type": {
    height: "16px",
    width: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    svg: {
      transform: "translateX(1px)",
    },
  },

  "&:active": {
    backgroundColor: colors.primary120,
    border: `2px solid ${colors.primary120}`,
  },

  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const LeftArrow = styled(Arrow)(() => ({
  left: 20,
}));

export const RightArrow = styled(Arrow)(() => ({
  right: 20,
}));

import { useRouter } from "next/router";
import { QueryParams } from "@constants/consts";
import { SearchTabs } from "./components/SearchModalTabBar/SearchModalTabBar";

const useSearchValues = () => {
  const { query } = useRouter();
  const searchValue = (query[QueryParams.SEARCH_KEYWORD] || "") as string;
  const tabValue = (query[QueryParams.SEARCH_TAB] || "") as SearchTabs;
  const isModalOpen = typeof query[QueryParams.SEARCH_KEYWORD] !== "undefined";

  return { searchValue, tabValue, isModalOpen };
};

export default useSearchValues;

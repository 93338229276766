import React, { ErrorInfo, ReactNode } from "react";
import { noticeError } from "@utils/common/newRelic";

interface IErrorBoundaryProps {
  children: ReactNode;
  onError?: (hasError: boolean, errorInfo: any) => ReactNode;
}

interface IErrorBoundaryState {
  hasError?: boolean;
  errorInfo?: any;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    noticeError(error, { errorInfo: `${errorInfo}` });

    this.setState({
      hasError: true,
      errorInfo,
    });
  }

  render(): ReactNode {
    if (this.state.hasError && this.props.onError) {
      const { hasError, errorInfo } = this.state;

      return this.props.onError.call(this, hasError, errorInfo);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

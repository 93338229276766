import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { ButtonContainer } from "@common/Buttons";
import { colors, fonts } from "@constants/cssVariables";

export const ErrorWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  margin: "0 auto",
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    width: 614,
    gap: theme.spacing(3),
  },
  [theme.breakpoints.up("xl")]: {
    flexDirection: "row-reverse",
    width: "unset",
    gap: theme.spacing(4),
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(4),

  [`${ButtonContainer}`]: {
    font: fonts.buttonLarge,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },

  [theme.breakpoints.up("xl")]: {
    alignItems: "flex-start",
    flex: 1,
  },
}));

export const HeaderText = styled("h1")(({ theme }) => ({
  font: fonts.header1,
  textAlign: "center",
  color: colors.neutral90,
  [theme.breakpoints.up("xl")]: {
    textAlign: "left",
  },
}));

export const DescriptionText = styled("p")(({ theme }) => ({
  font: fonts.bodyLarge,
  textAlign: "center",
  color: colors.neutral80,
  [theme.breakpoints.up("xl")]: {
    textAlign: "left",
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 600,
    height: 400,
  },
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    flex: 1,
  },
}));

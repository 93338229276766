import { keyframes, styled } from "@mui/material";
import { TabBarContainer } from "@navigation/TopNavigation/TabBar/TabBar.styled";

const searchModalTabBarAnimation = keyframes`
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  `;

export const AnimatedTabBarContainer = styled(TabBarContainer, {
  shouldForwardProp: (prop) => prop !== "shouldAnimateTabBar",
})<{ shouldAnimateTabBar: boolean }>(({ theme, shouldAnimateTabBar }) => ({
  [theme.breakpoints.up("lg")]: {
    ...(shouldAnimateTabBar && {
      animation: `${searchModalTabBarAnimation} 300ms ease-in forwards`,
    }),
  },
}));

import { styled } from "@mui/material";
import { QuoteIcon } from "@common/Icons";
import { colors, fonts } from "@constants/cssVariables";

const HighlightedQuoteWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: theme.spacing(3),
}));

const HighlightedQuoteIcon = styled(QuoteIcon)(({ theme }) => ({
  div: {
    svg: {
      width: "40px",
      height: "31px",
      color: colors.primary,
      [theme.breakpoints.up("md")]: {
        width: "48px",
        height: "37px",
      },
    },
  },
}));

const Quote = styled("p")(() => ({
  font: fonts.header3,
}));

const Author = styled("p")(() => ({
  font: fonts.bodyMedium,
  color: colors.neutral40,
}));

interface HighlightedQuoteProps {
  quote: string;
  author?: string;
}

const HighlightedQuote: React.FC<HighlightedQuoteProps> = ({ quote, author }) => {
  return (
    <HighlightedQuoteWrapper>
      <HighlightedQuoteIcon />
      <Quote>{quote}</Quote>
      {author && <Author>{author}</Author>}
    </HighlightedQuoteWrapper>
  );
};

export default HighlightedQuote;

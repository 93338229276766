import { styled } from "@mui/material/styles";
import { maxPageContentWidth } from "@constants/consts";

const PageContainer = styled("div")(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  display: "block",
  boxSizing: "border-box",
  maxWidth: maxPageContentWidth,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(24),
  },
}));

export default PageContainer;

import { PrimaryButton } from "@common/Buttons";
import * as Styled from "./CustomVideoError.styled";

export enum CustomErrorType {
  GEO_ERROR = "GEO_ERROR",
  AD_BLOCK_DETECTED = "AD_BLOCK_DETECTED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  NOT_FOUND = "NOT_FOUND",
}

export const errorDetails: Record<CustomErrorType, { header: string; body?: string; buttonText?: string }> = {
  [CustomErrorType.GEO_ERROR]: {
    header: "Deze video is niet beschikbaar op je huidige locatie",
    body: "Wij zien dat je deze site op dit moment buiten Nederland bezoekt. Vanwege uitzendrechten is deze video alleen binnen Nederland beschikbaar.",
    buttonText: "Laad opnieuw",
  },
  [CustomErrorType.AD_BLOCK_DETECTED]: {
    header: "AdBlock gedetecteerd",
    body: "Schakel je AdBlocker uit om de video te bekijken. Bedankt voor je ondersteuning!",
    buttonText: "Probeer opnieuw",
  },
  [CustomErrorType.NOT_AVAILABLE]: {
    header: "Deze video is momenteel niet beschikbaar",
  },
  [CustomErrorType.NOT_FOUND]: {
    header: "Video helaas niet gevonden",
  },
};

const CustomVideoError: React.FC<{ errorType: CustomErrorType }> = ({ errorType }) => {
  const { header, body, buttonText } = errorDetails[errorType] || {};
  return (
    <Styled.RelativeAspectRatio aspectRatio="16:9">
      <Styled.MessageContainer>
        <Styled.AlertIcon />
        <Styled.Header>{header}</Styled.Header>
        {body && <Styled.BodyText>{body}</Styled.BodyText>}
        {buttonText && <PrimaryButton onClick={() => window.location.reload()}>{buttonText}</PrimaryButton>}
      </Styled.MessageContainer>
    </Styled.RelativeAspectRatio>
  );
};

export default CustomVideoError;

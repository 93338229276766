import { styled } from "@mui/material";
import Icon from "@common/Icons/Icons";
import { BUTTON_SIZE, BUTTON_TYPE, VARIANT, iconSizes, variantColors } from "@constants/button";
import { IconEnum } from "@constants/consts";
import ButtonContainer from "../ButtonContainer";

interface IconButtonProps extends React.HTMLAttributes<HTMLElement> {
  component?: React.ElementType;
  disabled?: boolean;
  href?: string;
  icon: IconEnum;
  size: BUTTON_SIZE;
  variant: VARIANT;
  onClick?: React.MouseEventHandler;
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
  isNonInteractive?: boolean;
}

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== "as" && prop !== "size" && prop !== "variant",
})<{
  variant: VARIANT;
  size: BUTTON_SIZE;
}>(({ variant, size }) => ({
  svg: {
    display: "block",
    color: variantColors[variant].contentColor,
    height: iconSizes[size].height,
    width: iconSizes[size].width,
  },
}));

const IconButton: React.FC<IconButtonProps> = ({ component: Component = "button", variant, size, icon, ...props }) => {
  return (
    <ButtonContainer as={Component} buttonType={BUTTON_TYPE.ICON} variant={variant} size={size} {...props}>
      <StyledIcon icon={icon} variant={variant} size={size} />
    </ButtonContainer>
  );
};

export default IconButton;

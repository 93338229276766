import { styled } from "@mui/material";
import Icon from "@common/Icons/Icons";
import { colors, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";
import { ScrollArrowClassNames } from "./Swimlane";

export const Arrow = styled(Icon)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    zIndex: zIndex.gradient + 1,
    borderRadius: 26,
    top: "35%",
    transform: "translateY(-30%)",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: colors.neutral0,
    color: colors.primary,

    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 52,
      height: 52,
      svg: {
        width: 24,
        height: 24,
      },
    },

    "&:hover": {
      backgroundColor: colors.primary50,
      color: colors.neutral0,
    },

    "&:active": {
      backgroundColor: colors.primary120,
      color: colors.neutral0,
    },
  },
}));

export const LeftArrow = styled(Arrow)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    left: 16,
  },
}));

export const RightArrow = styled(Arrow)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    right: 16,
  },
}));

export const GradientDiv = styled("div")(({ theme }) => ({
  display: "none",
  position: "absolute",
  [theme.breakpoints.up("md")]: {
    display: "unset",
    height: "100%",
    zIndex: zIndex.gradient,
    width: 40,
  },
}));

export const LeftGradient = styled(GradientDiv)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    left: 0,
    background: gradients.fadeWhiteLeft,
  },
}));

export const RightGradient = styled(GradientDiv)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    right: 0,
    background: gradients.fadeWhiteRight,
  },
}));

export const SwimlaneWrapper = styled("div", { shouldForwardProp: (prop) => prop !== "showControlsOnHover" })<{
  showControlsOnHover: boolean;
}>(({ theme, showControlsOnHover }) => ({
  margin: theme.spacing(0, -4),
  position: "relative",

  [`&.${ScrollArrowClassNames.CAN_SCROLL_RIGHT}`]: {
    [`${LeftGradient}, ${LeftArrow}`]: {
      display: "none",
    },
  },

  [`&.${ScrollArrowClassNames.CAN_SCROLL_LEFT}`]: {
    [`${RightGradient}, ${RightArrow}`]: {
      display: "none",
    },
  },

  ...(showControlsOnHover && {
    [`&:not(:hover) `]: {
      [`${LeftArrow}, ${RightArrow}`]: {
        display: "none",
      },
    },
  }),

  [theme.breakpoints.up("md")]: {
    margin: "unset",
  },
}));

export const SwimlaneContainer = styled("div")(() => ({
  display: "flex",
  position: "relative",
  scrollBehavior: "smooth",
  overflowX: "auto",
  overflowY: "visible",
  scrollbarWidth: "none",
  msOverflowStyle: "none",

  // for smooth scrolling on iOS devices
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

import { FC, useState } from "react";
import Tealium, { JwMonitor } from "@4tn/webx-analytics";
import { styled } from "@mui/material";
import InlinePlayer from "@pageContent/ArticleInlinePlayer/InlinePlayer";
import Swimlane from "@pageContent/common/Swimlane";
import { LeftArrow, LeftGradient, RightArrow, RightGradient } from "@pageContent/common/Swimlane/Swimlane.styled";
import { CONTENT_LANGUAGE } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import VideoCollectionSwimlaneItem from "./components/VideoCollectionSwimlaneItem";

const firstChild = "& > div:first-of-type";
const swimlaneItemsSelector = "> div:last-child > div";

export const VideoCollectionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(6),
    margin: theme.spacing(10, 0),
  },
}));

export const VideoCollectionContainer = styled("div", { shouldForwardProp: (prop) => prop !== "hasSidebarVideoList" })<{
  hasSidebarVideoList?: boolean;
}>(({ theme, hasSidebarVideoList = true }) => ({
  display: "grid",
  position: "relative",
  gridTemplateColumns: "repeat(12, 1fr)",
  gap: theme.spacing(4),

  [`${firstChild}`]: {
    gridColumn: "span 12",
  },

  [theme.breakpoints.up("lg")]: {
    [`${firstChild}`]: {
      gridColumn: hasSidebarVideoList ? "span 8" : "span 12",
    },
  },
}));

export const SectionTitle = styled("h3")(() => ({
  font: fonts.header3,
}));

export const SwimlaneItems = styled(Swimlane, { shouldForwardProp: (prop) => prop !== "hasSidebarVideoList" })<{
  hasSidebarVideoList?: boolean;
}>(({ theme, hasSidebarVideoList = true }) => ({
  gridColumn: "span 12",

  ...(hasSidebarVideoList
    ? {
        [theme.breakpoints.up("lg")]: {
          position: "relative",
          overflowY: "auto",
          padding: theme.spacing(3.5, 0, 0, 3.5),
          borderRadius: 8,
          border: `2px solid ${colors.neutral10}`,
          gridColumn: "span 4",

          "> div": {
            marginBottom: theme.spacing(2),
            flexDirection: "column",
            position: "absolute",
            width: "calc(100% - 16px)",
          },

          "::-webkit-scrollbar": {
            width: "7px",
          },

          "::-webkit-scrollbar-thumb": {
            background: colors.neutral50,
            borderRadius: 8,
          },
          [`${LeftArrow}, ${RightArrow}, ${LeftGradient}, ${RightGradient}`]: {
            display: "none",
          },
        },
      }
    : {
        [swimlaneItemsSelector]: {
          display: "initial",
        },
      }),
}));

export const trackVideoItemClick = ({
  title,
  videoPosition,
  eventLabel,
  videoGuid,
  slug,
  updatedAt,
  createdAt,
}: VideoCollectionItem & {
  eventLabel: string;
  videoPosition: number;
}) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_PLAY,
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_label: eventLabel,
    event_value: videoPosition,
    media_id: videoGuid,
    media_name: title,
    content_id: slug || null,
    content_datecreated: createdAt || null,
    content_datepublished: createdAt || null,
    content_datemodified: updatedAt || null,
    content_language: CONTENT_LANGUAGE,
  });
};

interface VideoCollectionProps {
  title: string;
  items: Array<VideoCollectionItem>;
  autoplay?: boolean;
  hasSidebarVideoList?: boolean;
}

const VideoCollection: FC<VideoCollectionProps> = ({ items, title, autoplay, hasSidebarVideoList = true }) => {
  const [mainVideo, setMainVideo] = useState<VideoCollectionItem>(items[0]);
  const [playVideo, setPlayVideo] = useState(false);

  const onSwimlaneItemClick = (item: VideoCollectionItem, videoPosition: number) => {
    JwMonitor.setPlayReason("interaction");
    trackVideoItemClick({ ...item, eventLabel: title, videoPosition });
    setMainVideo(item);
    setPlayVideo(true);
  };

  if (!items.length) {
    return null;
  }

  return (
    <VideoCollectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      <VideoCollectionContainer hasSidebarVideoList={hasSidebarVideoList}>
        <InlinePlayer
          title={mainVideo.title}
          duration={mainVideo.duration}
          imageUrl={mainVideo.imageUrl}
          videoGuid={mainVideo.videoGuid}
          playerLoadedInitially={playVideo || autoplay}
        />
        <SwimlaneItems scrollToItemIndex={1} hasSidebarVideoList={hasSidebarVideoList}>
          {items.map((item, index) => (
            <VideoCollectionSwimlaneItem<VideoCollectionItem>
              key={item.id}
              videoPosition={index + 1}
              item={item}
              isMainVideo={item.id === mainVideo.id}
              onItemClick={onSwimlaneItemClick}
            />
          ))}
        </SwimlaneItems>
      </VideoCollectionContainer>
    </VideoCollectionWrapper>
  );
};

export default VideoCollection;

import { styled } from "@mui/material";
import { ButtonContainer } from "@common/Buttons";
import { colors, fonts } from "@constants/cssVariables";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: colors.neutral10,
  padding: theme.spacing(6, 4),
  borderRadius: "6px",
  [`${ButtonContainer}`]: {
    marginTop: theme.spacing(4),
  },
}));

export const Title = styled("h4")(({ theme }) => ({
  font: fonts.header4,
  color: colors.neutral90,
  marginBottom: theme.spacing(4),
}));

import { AccordionDetails, AccordionSummary } from "@mui/material";
import { DownChevronIcon } from "@common/Icons";
import RichText from "@pageContent/RichText";
import { AccordionNode } from "@typings/richText";
import * as Styled from "./Accordion.styled";

const Accordion: React.FC<AccordionNode["data"]> = ({ title, text, id }) => {
  return (
    <Styled.Accordion>
      <AccordionSummary expandIcon={<DownChevronIcon />} aria-controls={id} id={id}>
        <Styled.Summary>{title}</Styled.Summary>
      </AccordionSummary>
      <AccordionDetails>
        <RichText richText={text} />
      </AccordionDetails>
    </Styled.Accordion>
  );
};

export default Accordion;

import { useMemo, useState } from "react";
import { useRouter } from "next/router";
import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { RichText } from "@constants/richText";
import { TableNode, TableRowNode } from "@typings/richText";
import { FeatureSlugs, useFeature } from "@utils/common/featureTooling";
import { nodeListToReactComponents } from "@utils/pageContent/richTextToJSX";
import * as Styled from "./RichTextTable.styled";

const columnSeparator = " | ";

const normalize = (text: string) => text.normalize("NFD").replace(/[\u0300-\u036f`]/g, "");

const sanitizeRichText = (rowNode: TableRowNode): string => {
  if (!rowNode) return "";

  const cellContent = rowNode.content.map((cellNode) =>
    cellNode.content
      .flatMap((blockNode) =>
        blockNode.content.map((node) => {
          if (node.nodeType === RichText.TEXT) return node.value;
          if (node.nodeType === RichText.HYPERLINK) return node.content.map((text) => text.value);
          return "";
        })
      )
      .join("")
  );
  return normalize(cellContent.join(columnSeparator));
};

const TableFilter: React.FC<{
  value: string;
  onChange: (value: string) => void;
  variables: { [slug: string]: string };
}> = ({ value, onChange, variables }) => {
  const { asPath } = useRouter();
  const [, slugA, slugB] = asPath.split(/[/?]/);
  const placeholder = variables[slugA] || variables[slugB];

  if (!placeholder) return null;
  return (
    <Styled.FilterInput value={value} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} />
  );
};

const RichTextTable: React.FC<TableNode> = ({ content }) => {
  const [filter, setFilter] = useState("");
  const { enabled, variables } = useFeature<{ [slug: string]: string }>(FeatureSlugs.TABLE_FILTER);
  const [headerRow, bodyRows] = useMemo(() => {
    const matchesStartOfWord = (sanitized: string) => {
      const normalizedFilter = normalize(filter);
      const regex = new RegExp(`\\b${normalizedFilter}`, "i");
      return regex.test(sanitized);
    };

    let hiddenColumns: number[] = [];

    const rows = [...content]
      .map((row, index) => {
        const [firstColumn] = row.content;
        const isHeader = firstColumn.nodeType === RichText.TABLE_HEADER_CELL;
        const sanitized = sanitizeRichText(row);
        if (isHeader) {
          hiddenColumns = sanitized
            .split(columnSeparator)
            .map((text, index) => (text === "hidden" ? index : null))
            .filter((value) => value !== null) as number[];
        }
        return {
          id: `row-${index}`,
          isHeader,
          sanitized,
          content: row.content
            .map((column, colIndex) => ({
              id: `column-${index}-${colIndex}`,
              hidden: hiddenColumns.includes(colIndex),
              children: nodeListToReactComponents(column.content),
            }))
            .filter((column) => !column.hidden),
        };
      })
      .filter((row) => row.isHeader || !filter || matchesStartOfWord(row.sanitized));

    let headerRow;
    if (rows[0].isHeader) {
      headerRow = rows.shift();
    }
    return [headerRow, rows];
  }, [content, filter]);

  return (
    <div>
      {enabled && variables && <TableFilter value={filter} onChange={setFilter} variables={variables} />}
      <TableContainer>
        <MuiTable>
          {headerRow && (
            <TableHead>
              <TableRow>
                {headerRow.content.map((column) => (
                  <Styled.TableHeaderCell key={column.id}>{column.children}</Styled.TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {!bodyRows.length && (
              <Styled.Row>
                <TableCell colSpan={headerRow?.content.length || 1}>No results</TableCell>
              </Styled.Row>
            )}
            {bodyRows.map((row) => (
              <Styled.Row key={row.id}>
                {row.content.map((column) => (
                  <TableCell key={column.id}>{column.children}</TableCell>
                ))}
              </Styled.Row>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
};

export default RichTextTable;

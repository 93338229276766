import {
  QueryFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { paginatedFetch } from "@utils/pageContent/fetchData";
import { useNoticeError } from "./newRelic";

type UsePaginatedFetchResult<T> = UseInfiniteQueryResult<ReturnFetchData<T>, Error> & {
  items?: T[];
};

const usePaginatedFetch = <T = unknown>({
  collectionName,
  endpoint,
  limit,
  ...args
}: UseInfiniteQueryOptions<ReturnFetchData<T>, Error> &
  (
    | {
        queryFn?: undefined;
        collectionName: string;
        endpoint: string;
        limit: number;
      }
    | {
        queryFn: QueryFunction<ReturnFetchData<T>>;
        collectionName?: undefined;
        endpoint?: undefined;
        limit?: undefined;
      }
  )): UsePaginatedFetchResult<T> => {
  const queryKey = args.queryKey || [collectionName, endpoint, limit];
  const result = useInfiniteQuery<ReturnFetchData<T>, Error>({
    ...(endpoint &&
      limit && {
        queryFn: ({ pageParam = 1 }) => paginatedFetch<T>({ endpoint, limit, page: pageParam }),
      }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    select: (data) => ({
      ...data,
      items: data.pages.flatMap((page) => page.items),
    }),
    initialDataUpdatedAt: 0,
    ...args,
    queryKey,
  });

  useNoticeError(result.error, { queryKey: queryKey.join(",") });

  const { items } = (result.data as unknown as { items?: T[] }) || {};
  return { ...result, items };
};

export default usePaginatedFetch;

import Image from "next/image";
import Link from "next/link";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { TitleWrapper } from "@pageContent/common/InfiniteCollection/InfiniteCollection";
import Swimlane from "@pageContent/common/Swimlane";
import { LeftArrow, RightArrow, SwimlaneContainer } from "@pageContent/common/Swimlane/Swimlane.styled";
import { firstChildSelector, lastChildSelector } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";

export const RadioScheduleContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),
  position: "relative",

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 0),
  },
}));

export const RadioScheduleHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
}));

export const RadioScheduleTitle = styled("p")(() => ({
  font: fonts.header3,
  color: colors.neutral90,
}));

export const RadioScheduleShowAll = styled("div")(() => ({
  font: fonts.buttonMedium,
  color: colors.primary,
}));

export const ScheduleSwimlane = styled(Swimlane)(({ theme }) => ({
  [`${SwimlaneContainer}`]: {
    gap: theme.spacing(2),
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(4),
    },
  },

  [`${LeftArrow}, ${RightArrow}`]: {
    top: "50%",
    transform: "translateY(-26px)",
    backgroundColor: colors.secondary100,
    color: colors.neutral0,

    "&:hover": {
      backgroundColor: colors.secondary120,
    },

    "&:active": {
      backgroundColor: colors.secondary50,
    },
  },
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, -20),
  },
}));

export const ItemContainer = styled(Link)(({ theme }) => ({
  backgroundColor: colors.primary,
  borderRadius: 6,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  maxWidth: 238,
  position: "relative",
  cursor: "pointer",

  [lastChildSelector]: {
    marginRight: theme.spacing(4),

    [`${theme.breakpoints.up("md")}`]: {
      marginRight: theme.spacing(20),
    },
  },

  [`${theme.breakpoints.up("md")}`]: {
    maxWidth: 300,
    transform: "scale(1)",
    transition: "transform 0.4s ease-in-out",

    "&:hover": {
      transform: "scale(1.05)",
    },
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 4, 0),
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

export const TimeAndTitleContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const LiveLabel = styled("span")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.buttonSmall,
  backgroundColor: colors.secondary100,
  padding: theme.spacing(1, 1.5),
  textAlign: "center",
  borderRadius: 26,
  whiteSpace: "nowrap",
  position: "absolute",
  top: 16,
  right: 16,
}));

export const ShowTime = styled("span")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.bodyMedium,
  marginBottom: theme.spacing(0.5),
}));

export const ShowTitle = styled("h2")(() => ({
  font: fonts.header2,
  color: colors.neutral0,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
  overflowWrap: "break-word",
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  width: 238,
  borderBottomRightRadius: 6,
  borderBottomLeftRadius: 6,
  backgroundColor: colors.primary,
  overflow: "hidden",

  [`${theme.breakpoints.up("md")}`]: {
    width: 300,
  },
}));

export const ShowImage = styled(Image)(() => ({
  objectFit: "cover",
  objectPosition: "top",
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
}));

export const DayDivider = styled("div")(({ theme }) => ({
  backgroundColor: colors.primary140,
  borderRadius: 8,
  display: "block",
  paddingTop: theme.spacing(10.5),
  minWidth: 110,
  textAlign: "center",
  font: fonts.header2,
  color: colors.neutral0,

  [firstChildSelector]: {
    marginLeft: theme.spacing(4),

    [`${theme.breakpoints.up("md")}`]: { marginLeft: theme.spacing(20) },
  },

  [`${theme.breakpoints.up("lg")}`]: {
    minWidth: 90,
  },
}));

export const ImageBackground = styled("div")(() => ({
  position: "absolute",
  zIndex: 0,
}));

export const RadioScheduleSkeletonWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

export const ItemContainerSkeleton = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 238,
  height: 284.5,
  borderRadius: 6,

  [`${theme.breakpoints.up("md")}`]: {
    width: 300,
    height: 363,
  },
}));

export const DividerSkeleton = styled("div")(({ theme }) => ({
  width: 110,
  height: 284.5,
  borderRadius: 6,

  [`${theme.breakpoints.up("md")}`]: {
    height: 363,
  },

  [`${theme.breakpoints.up("lg")}`]: {
    width: 90,
  },
}));

export const ContainerSkeleton = styled("div")(({ theme }) => ({
  overflow: "hidden",
  margin: theme.spacing(8, -20, 8, -4),
  position: "relative",

  [`${TitleWrapper}`]: {
    marginLeft: theme.spacing(4),
  },

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, -20, 10, 0),

    [`${TitleWrapper}`]: {
      marginLeft: 0,
    },
  },
}));

import Image from "next/image";
import { styled } from "@mui/material/styles";
import { colors, fonts } from "@constants/cssVariables";

export const imageEmbedImageTestId = "imageEmbedimage";

// TODO: Replace this hardcoded value with the one from config once https://talpa.atlassian.net/browse/WXT-84 (moving on from the federated NextJS approach) is complete
const cloudinaryDomain = "https://cldnr.prod.webx.talpa.digital/talpa-network";

const imageEmbedCustomCloudinaryLoader = ({ src, width }: { src: string; width: number }) => {
  const roundedBordersParamValue = width >= 900 ? "r_8" : "r_6";
  const params = ["f_auto", "c_limit", `w_${width}`, roundedBordersParamValue];

  return `${cloudinaryDomain}/image/fetch/${params.join(",")}/${src}`;
};

const ImageEmbedContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const ImageEmbedImageContainer = styled("div")(() => ({
  display: "flex",
  maxHeight: 900,
}));

const ImageEmbedImage = styled(Image)(() => ({
  height: "auto",
  maxWidth: "100%",
  objectFit: "contain",
  objectPosition: "left",
}));

const ImageEmbedDescription = styled("p")(() => ({
  font: fonts.bodySmall,
  color: colors.neutral40,
}));

interface ImageEmbedProps {
  fileUrl: string;
  height: number;
  width: number;
  description?: string;
}

const ImageEmbed: React.FC<ImageEmbedProps> = ({ fileUrl, width, height, description }) => {
  return (
    <ImageEmbedContainer>
      <ImageEmbedImageContainer>
        <ImageEmbedImage
          loader={imageEmbedCustomCloudinaryLoader}
          src={fileUrl}
          alt={description || ""}
          height={height}
          width={width}
          sizes="(max-width: 900px) 100vw, 740px"
          data-testid={imageEmbedImageTestId}
        />
      </ImageEmbedImageContainer>
      <ImageEmbedDescription>{description}</ImageEmbedDescription>
    </ImageEmbedContainer>
  );
};

export default ImageEmbed;

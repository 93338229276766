import { noticeError } from "./newRelic";

export const getConsentData = async (): Promise<{ tcString: string; talpaConsent: string }> =>
  new Promise((resolve) => {
    if (typeof window.__tcfapi === "undefined") {
      resolve({ tcString: "", talpaConsent: "0" });
      return;
    }

    const timeout = setTimeout(() => {
      noticeError(new Error("Failed to retrieve consent"));
      resolve({ tcString: "", talpaConsent: "0" });
    }, 2000);

    window.__tcfapi("getTCData", 2, (data, success) => {
      clearTimeout(timeout);
      if (success) {
        return resolve({
          tcString: data.tcString,
          talpaConsent: [2, 3, 4, 7, 9].every((purpose) => data.purpose.consents[purpose] === true) ? "1" : "0",
        });
      }
      return resolve({ tcString: "", talpaConsent: "0" });
    });
  });

import { useEffect, useMemo, useRef, useState } from "react";
import useSearchValues from "@navigation/SearchModal/useSearchValues";
import { searchInputPlaceholderText } from "@constants/consts";
import updateSearchParams from "@utils/navigation/updateSearchParams";
import * as Styled from "./SearchModalTopBar.styled";

export const backIconAriaLabel = "back";
export const searchInputAriaLabel = "search";
export const clearInputIconAriaLabel = "clear";

const SearchModalTopBar: React.FC = () => {
  const { searchValue, isModalOpen } = useSearchValues();
  const searchModalInputRef = useRef<HTMLInputElement>(null);
  const [inputText, setInputText] = useState(searchValue);
  const debounceTimer = useRef<NodeJS.Timeout | number | null>(null);

  useEffect(() => {
    return () => {
      // Cleanup debounce timer
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [debounceTimer]);

  const handleCloseSearchModal = () => {
    updateSearchParams(null);
  };

  const handleSearchModalInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    const { value } = event.target;
    setInputText(value);
    if (value.length >= 3) debounceTimer.current = setTimeout(updateSearchParams, 500, value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
      updateSearchParams(inputText);
    }
  };

  const handleSearchReset = () => {
    setInputText("");
    updateSearchParams("");
    searchModalInputRef.current?.blur();
  };

  // Icon is memoized so the transformation animation doesn't get triggered on every rerender
  const memoizedSearchModalBackIcon = useMemo(
    () => <Styled.SearchModalBackIcon onClick={handleCloseSearchModal} role="button" aria-label={backIconAriaLabel} />,
    []
  );

  return (
    <Styled.SearchModalTopBarContainer>
      <Styled.SearchModalTopBarContentWrapper>
        {memoizedSearchModalBackIcon}
        <Styled.SearchModalInputContainer isModalOpen={isModalOpen}>
          <Styled.SearchModalSearchIcon />
          <Styled.SearchModalInput
            ref={searchModalInputRef}
            aria-label={searchInputAriaLabel}
            placeholder={searchInputPlaceholderText}
            value={inputText}
            onChange={handleSearchModalInputChange}
            onKeyDown={handleKeyDown}
            spellCheck={false}
            autoFocus
          />
          {inputText && (
            <Styled.SearchModalClearInputIcon
              onClick={handleSearchReset}
              role="button"
              aria-label={clearInputIconAriaLabel}
            />
          )}
        </Styled.SearchModalInputContainer>
      </Styled.SearchModalTopBarContentWrapper>
    </Styled.SearchModalTopBarContainer>
  );
};

export default SearchModalTopBar;

import { styled } from "@mui/material";
import ImageSkeleton from "./ImageSkeleton";
import TextSkeleton from "./TextSkeleton";

const ArticleSkeletonWrapper = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 3),
  gap: theme.spacing(2),
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    width: "auto",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
}));

const ArticleSkeleton: React.FC<{ showCategory?: boolean }> = ({ showCategory }) => (
  <ArticleSkeletonWrapper>
    <ImageSkeleton aspectRatio="16:9" />
    <TextContainer>
      <TextSkeleton width={"100%"} />
      <TextSkeleton width={"100%"} />
      <TextSkeleton width={"70%"} />
      {showCategory && <TextSkeleton sx={{ marginTop: 2, display: ["none", "none", "block"] }} width={"50%"} />}
    </TextContainer>
  </ArticleSkeletonWrapper>
);

export default ArticleSkeleton;

import { styled } from "@mui/material";
import { VIDEO_PORTAL_ID, transitions } from "@constants/consts";
import { colors, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const smallerScreenSizesPlayerHeight = 64;
export const largerScreenSizesPlayerHeight = 96;

export const AudioPlayerContainer = styled("div")<{ inDashboardView: boolean }>(({ theme, inDashboardView }) => ({
  position: "fixed",
  zIndex: zIndex.floating,
  bottom: 0,
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  width: "calc(100% - 16px)",
  display: "flex",
  height: smallerScreenSizesPlayerHeight,
  justifyContent: "space-between",
  alignItems: "center",
  background: gradients.gradient04,
  borderRadius: 12,
  transition: transitions.hamburgerMenu,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 4, 0, 2),
  },

  [theme.breakpoints.up("md")]: {
    width: "100%",
    margin: 0,
    height: largerScreenSizesPlayerHeight,
    padding: theme.spacing(0, 8),
    borderRadius: 0,
    transform: `translateY(${inDashboardView ? "100%" : 0})`,
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 20),
  },

  [theme.breakpoints.up("xl")]: {
    padding: theme.spacing(0, 24),
  },

  [`#${VIDEO_PORTAL_ID}`]: {
    position: "absolute",
    right: 0,
    bottom: 72,
    width: 393,

    [theme.breakpoints.up("md")]: {
      right: 80,
      bottom: 104,
    },

    [theme.breakpoints.up("lg")]: {
      right: 96,
    },
  },

  ".jwplayer.jw-flag-live ": {
    ".jw-icon-display": {
      width: 52,
      height: 52,
      background: colors.secondary100,

      svg: {
        maskSize: "24px !important",
      },

      ".jw-svg-icon-play, .jw-svg-icon-pause, .jw-svg-icon-play:hover, .jw-svg-icon-pause:hover": {
        backgroundColor: colors.neutral100,
      },
    },

    ".jw-controls .jw-settings-menu": {
      width: "100%",
      height: "100%",
      maxWidth: "none",
      maxHeight: "none",
      bottom: 0,
      right: 0,
    },

    ".jw-settings-open .jw-controlbar": {
      display: "none",
    },
  },
}));

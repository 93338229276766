import { IncomingHttpHeaders } from "http";
import { ViewType } from "@constants/consts";

export function isIos(): boolean {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i) !== null;
}

export function isAndroid(): boolean {
  return navigator.userAgent.match(/(android)/i) !== null;
}

export function isMobile(userAgent = navigator.userAgent): boolean {
  return userAgent.toLowerCase().match(/mobile/i) !== null;
}

export function isIosChrome(): boolean {
  return /CriOS/.test(navigator.userAgent) && isMobile();
}

export function isFacebook(): boolean {
  return navigator.userAgent.match(/FBAN|FBAV|FBIOS/i) !== null;
}

export const getViewMode = (headers?: IncomingHttpHeaders): ViewType => {
  if (!headers) return ViewType.DESKTOP;
  if (headers["true-cloudfront-is-tablet-viewer"] === "true") return ViewType.TABLET;
  if (headers["true-cloudfront-is-mobile-viewer"] === "true") return ViewType.MOBILE;
  if (headers["true-cloudfront-is-desktop-viewer"] === "true") return ViewType.DESKTOP;
  // Fallback for local and preview deployments
  if (isMobile(headers["user-agent"] || "")) return ViewType.MOBILE;
  return ViewType.DESKTOP;
};

import { FC } from "react";
import ErrorBoundary from "@common/ErrorBoundary/ErrorBoundary";
import TopAndTabBarContainer from "@common/TopAndTabBarContainer";
import TabBar from "./TabBar";
import TopBar from "./TopBar";
import Hamburger from "./TopBar/Hamburger";
import { TopBarContainer } from "./TopBar/TopBar.styled";

interface TopNavigationProps {
  hamburgerMenu?: Navigation | null;
  topNavigation?: Navigation | null;
}

const TopNavigation: FC<TopNavigationProps> = ({ hamburgerMenu, topNavigation }) => (
  <>
    <ErrorBoundary onError={() => <></>}>
      <Hamburger navigation={hamburgerMenu} />
    </ErrorBoundary>
    <TopAndTabBarContainer>
      <ErrorBoundary onError={() => <TopBarContainer />}>
        <TopBar />
      </ErrorBoundary>
      <ErrorBoundary onError={() => <></>}>
        <TabBar navigation={topNavigation} />
      </ErrorBoundary>
    </TopAndTabBarContainer>
  </>
);

export default TopNavigation;

import { Skeleton, styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { colors } from "@constants/cssVariables";

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 115,
  maxWidth: "none",
  backgroundColor: colors.neutral10,
  height: 76,

  [theme.breakpoints.up("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

const ImageSkeleton: React.FC<{ aspectRatio: "16:9" | "1:1" | "2:3" }> = ({ aspectRatio }) => (
  <StyledSkeleton data-testid="image-skeleton" variant="rounded">
    <AspectRatioContainer aspectRatio={aspectRatio} />
  </StyledSkeleton>
);

export default ImageSkeleton;

import { Skeleton } from "@mui/material";
import { colors } from "@constants/cssVariables";

export const socialEmbedSkeletonDataTestId = "socialEmbedSkeletonDataTestId";

const SocialEmbedSkeleton: React.FC = () => (
  <Skeleton
    sx={{ backgroundColor: colors.neutral10 }}
    variant="rounded"
    height={500}
    width="100%"
    data-testid={socialEmbedSkeletonDataTestId}
  />
);

export default SocialEmbedSkeleton;

import { FC, ReactNode, useEffect, useRef } from "react";
import { IconEnum } from "@constants/consts";
import * as Styled from "./Swimlane.styled";

const offsetValue = 50;

const onArrowClick = (swimlaneRef: HTMLDivElement | null, direction: Direction) => {
  if (!swimlaneRef) return null;

  const step = direction === Direction.LEFT ? -swimlaneRef.clientWidth : swimlaneRef.clientWidth;
  const targetPosition = swimlaneRef.scrollLeft + step;
  swimlaneRef.scrollTo({ left: targetPosition, behavior: "smooth" });
};

const getOffsetToAlign = () => {
  const pageContainerElement = document.getElementById("page-container");
  if (!pageContainerElement) return 0;

  const pageContainerPaddingLeft = parseFloat(window.getComputedStyle(pageContainerElement).paddingLeft);
  return pageContainerPaddingLeft - 16 === 0 ? 16 : 80;
};

const scrollToItem = (swimlaneRef: HTMLDivElement | null, index: number, alignScrolledItem?: boolean) => {
  if (!swimlaneRef) return null;

  const item = swimlaneRef.children[index] as HTMLDivElement;
  if (item) {
    const scrollItemAlignmentValue = alignScrolledItem ? getOffsetToAlign() : 0;
    const offset = alignScrolledItem ? scrollItemAlignmentValue : offsetValue;
    const targetPosition = item.offsetLeft - offset;
    swimlaneRef.scrollTo({ left: targetPosition, behavior: "smooth" });
  }
};

enum Direction {
  LEFT,
  RIGHT,
}

export enum ScrollArrowClassNames {
  CAN_SCROLL_LEFT = "can-scroll-left",
  CAN_SCROLL_RIGHT = "can-scroll-right",
}

interface SwimlaneProps {
  className?: string;
  children: ReactNode;
  scrollToItemIndex?: number;
  alignScrolledItem?: boolean;
  hasHoverEffect?: boolean;
}

const Swimlane: FC<SwimlaneProps> = ({
  className,
  children,
  scrollToItemIndex,
  hasHoverEffect = false,
  alignScrolledItem,
}) => {
  const swimlaneRef = useRef<HTMLDivElement>(null);
  const swimlaneWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // on component mount, scroll to the first item minus offset
    scrollToItemIndex !== undefined && scrollToItem(swimlaneRef.current, scrollToItemIndex, alignScrolledItem);
    addArrowClasses(swimlaneRef.current);
  }, [scrollToItemIndex, alignScrolledItem]);

  const handleArrowClick = (direction: Direction) => () => onArrowClick(swimlaneRef.current, direction);

  const addArrowClasses = (target: HTMLDivElement | null) => {
    if (!target || !swimlaneWrapperRef.current) return;

    const isLeft = target.scrollLeft === 0;

    const tolerance = 1;
    const isRight = Math.abs(Math.round(target.scrollWidth - target.scrollLeft) - target.clientWidth) <= tolerance;

    swimlaneWrapperRef.current.classList.toggle(ScrollArrowClassNames.CAN_SCROLL_RIGHT, isLeft);
    swimlaneWrapperRef.current.classList.toggle(ScrollArrowClassNames.CAN_SCROLL_LEFT, isRight);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    addArrowClasses(target);
  };

  return (
    <Styled.SwimlaneWrapper
      ref={swimlaneWrapperRef}
      className={`${className ? className : ""}`}
      showControlsOnHover={hasHoverEffect}
    >
      <Styled.LeftArrow
        data-testid="left-arrow"
        icon={IconEnum.LEFT_CHEVRON}
        onClick={handleArrowClick(Direction.LEFT)}
      />
      <Styled.RightArrow
        data-testid="right-arrow"
        icon={IconEnum.RIGHT_CHEVRON}
        onClick={handleArrowClick(Direction.RIGHT)}
      />
      <Styled.LeftGradient />
      <Styled.RightGradient />
      <Styled.SwimlaneContainer ref={swimlaneRef} onScroll={handleScroll} data-testid="vc-swim-lane">
        {children}
      </Styled.SwimlaneContainer>
    </Styled.SwimlaneWrapper>
  );
};

export default Swimlane;

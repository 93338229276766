import { ReactNode } from "react";
import { styled } from "@mui/material";
import Icon from "@common/Icons";
import { IconEnum } from "@constants/consts";
import { colors, fonts, opacities } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

const LabelWrapper = styled("div")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.buttonSmall,
  backgroundColor: opacities.opacity02,
  borderRadius: 26,
  height: 22,
  padding: theme.spacing(1, 2),
  margin: 0,
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
}));

const LabelIcon = styled(Icon)({
  svg: {
    color: colors.neutral0,
    width: 14,
    height: 14,
    zIndex: zIndex.baseControls,
  },
});

interface LabelProps {
  icon?: IconEnum;
  iconTestId?: string;
  text: number | string;
  children?: ReactNode;
  className?: any;
}
const Label: React.FC<LabelProps> = ({ icon, iconTestId, text, className, children }) => {
  return (
    text && (
      <LabelWrapper className={className}>
        {icon && <LabelIcon icon={icon} data-testid={iconTestId} />}
        <span>{text}</span>
        {children}
      </LabelWrapper>
    )
  );
};

export default Label;

const secondsToISODuration = (duration: number): string => {
  const time = Math.round(duration);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor((time - hours * 60 * 60) / 60);
  const seconds = time - hours * 60 * 60 - minutes * 60;

  let formattedDuration = "PT";
  if (hours > 0) {
    formattedDuration = `${formattedDuration}${hours}H`;
  }
  if (minutes > 0) {
    formattedDuration = `${formattedDuration}${minutes}M`;
  }
  return `${formattedDuration}${seconds}S`;
};

export default secondsToISODuration;

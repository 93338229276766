import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

async function getDrmToken(): Promise<string> {
  try {
    const { videoApiEndpoint } = publicRuntimeConfig;
    const response = await fetch(`${videoApiEndpoint}?query=${encodeURIComponent("{ drmToken { token } }")}`, {});
    const { data } = await response.json();
    return data?.drmToken.token || "";
  } catch (ignored) {}
  return "";
}

export const appendAuthHeader = async (request: any): Promise<void> => {
  const drmToken = await getDrmToken();
  request.headers = {
    Authorization: `Basic ${drmToken}`,
    "Content-Type": "application/json",
  };
};

const addDrm = (orginalSource: any) => {
  const source = JSON.parse(JSON.stringify(orginalSource));

  if (!source.drm) return source;
  if (source.drm.widevine) {
    const { releasePid } = source.drm.widevine;
    source.drm.widevine.licenseRequestFilter = async (request: any): Promise<void> => {
      await appendAuthHeader(request);
      const licenseRequestBase64 = btoa(
        new Uint8Array(request.body).reduce((data, byte) => data + String.fromCharCode(byte), "")
      );
      request.body = JSON.stringify({
        getRawWidevineLicense: {
          releasePid,
          widevineChallenge: licenseRequestBase64,
        },
      });
    };
  }
  if (source.drm.fairplay) {
    const { releasePid } = source.drm.fairplay;
    Object.assign(source.drm.fairplay, {
      licenseResponseType: "json",
      licenseRequestMessage: (message: any) =>
        btoa(new Uint8Array(message).reduce((data, byte) => data + String.fromCharCode(byte), "")),
      extractContentId: (): string => releasePid,
      licenseRequestFilter: async (request: any): Promise<void> => {
        await appendAuthHeader(request);
        request.body = JSON.stringify({
          getFairplayLicense: {
            releasePid,
            spcMessage: request.body,
          },
        });
      },
      licenseResponseFilter: (response: any) => {
        response.data = Uint8Array.from(atob(response.data.getFairplayLicenseResponse.ckcResponse), (c) =>
          c.charCodeAt(0)
        );
      },
      licenseResponseMessage: (message: BlobPart): Blob =>
        new window.Blob([message], { type: "application/octet-binary" }),
    });
  }
  if (source.drm.playready) {
    source.drm.playready.licenseRequestFilter = async (request: any) => {
      const drmToken = await getDrmToken();
      const [, account, token] = atob(drmToken).match(/(http:.+):(.+)/) || [];
      request.uris[0] += `&account=${account}&auth=${token}`;
    };
  }
  return source;
};

export default addDrm;

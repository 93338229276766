const GREEN = "darkgreen";
const YELLOW = "darkgoldenrod";
const assetsDomain = "https://assets.prod.webx.talpa.digital";
const honeypots = ["/ad/banner.gif", "/ad/popup.gif", "/ad/view/vghd.gif"];
const debug = typeof window !== "undefined" && document.cookie.includes("__WXA_DEBUG__");

function log(message: string, color: string = GREEN, ...args: any): void {
  if (debug) console.log(`%c ${message}`, `background-color: ${color}; color: white`, ...args);
}

const uuid = () => (Math.random() + 1).toString(36).substring(2);

const userId = uuid();

const baitId = "topbanner_ad";

let failedToLoadBait = false;

function isBaitBlocked(): boolean {
  if (failedToLoadBait) return true;
  const elem = document.getElementById(baitId);
  if (
    !elem ||
    elem.offsetParent === null ||
    elem.offsetHeight == 0 ||
    elem.offsetWidth == 0 ||
    elem.clientHeight == 0 ||
    elem.clientWidth == 0
  ) {
    log("Bait element is manipulated ", YELLOW, elem);
    return true;
  } else if (window.getComputedStyle !== undefined) {
    const elemCS = window.getComputedStyle(elem, null);
    if (elemCS && (elemCS.getPropertyValue("display") == "none" || elemCS.getPropertyValue("visibility") == "hidden")) {
      log("Bait element is hidden", YELLOW);
      return true;
    }
  }
  log("Bait seems alright ", GREEN);
  return false;
}

function fetchBait(honeypot: string): Promise<{ response: Blob; baitUrl: string }> {
  const baitUrl = `${assetsDomain}${honeypot}?user=${userId}&slot=pre&ad-sense=1&adId=${Date.now()}&ad_size=600x120`;

  return new Promise(async (resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function (): void {
      if (this.readyState === 4) {
        if (this.status >= 200 && this.status < 300) return resolve({ response: this.response, baitUrl });

        return reject(honeypot);
      }
      // request not ready yet
    };
    xhr.open("GET", baitUrl);
    xhr.responseType = "blob";
    xhr.send();
  });
}

function createBaitElement(): Promise<boolean> {
  return new Promise(async (resolve) => {
    // clean up
    const elem = document.getElementById(baitId);
    if (elem) elem.remove();

    const bait = document.createElement("img");
    bait.setAttribute("id", baitId);
    bait.setAttribute(
      "class",
      "pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner AdBody"
    );
    bait.setAttribute("style", "width: 1px !important; height: 1px !important; position: absolute !important;");

    try {
      const [{ response, baitUrl }] = await Promise.all(honeypots.map(fetchBait));
      log(`All baits loaded `, GREEN);
      failedToLoadBait = false;
      const url = window.URL || window.webkitURL;
      bait.setAttribute("src", typeof url?.createObjectURL === "function" ? url.createObjectURL(response) : baitUrl);
      document.body.appendChild(bait);
      resolve(false);
    } catch (error: unknown) {
      failedToLoadBait = true;
      log(`Bait ${error} failed to load `, YELLOW);
      resolve(true);
    }
  });
}

export default async function detectAdBlock(): Promise<boolean> {
  return new Promise((resolve) => {
    createBaitElement().then((failed) => {
      if (failed) {
        return resolve(true);
      }
      setTimeout(() => {
        const baitBlocked = isBaitBlocked();

        resolve(baitBlocked);
      }, 50);
    });
  });
}

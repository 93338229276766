export const filterBitrateOptions = () => {
  const subtitleMenu = document.querySelector<HTMLDivElement>(
    ".jw-settings-submenu-quality .jw-settings-submenu-items"
  );
  if (subtitleMenu) {
    const qualityLevels = new Set<string>();
    (subtitleMenu.childNodes as NodeListOf<HTMLButtonElement>).forEach((item: HTMLButtonElement) => {
      const buttonText = item.innerHTML;
      if (buttonText.match(/^\d+p\s{1}\(.+\)/)) {
        const [label] = buttonText.split(" ");
        // use pretty label
        item.innerHTML = label;
        if (qualityLevels.has(label)) {
          // hide double entries
          item.style.display = "none";
          return;
        }
        qualityLevels.add(label);
      }
    });
  }
};

import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

export const TextContainer = styled("div", { shouldForwardProp: (prop) => prop !== "hasTopMargin" })<{
  hasTopMargin: boolean;
}>(({ theme, hasTopMargin }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  margin: theme.spacing(16, 0),
  marginTop: hasTopMargin ? theme.spacing(2) : undefined,
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(6),
    margin: theme.spacing(16, 0, 10),
    marginTop: hasTopMargin ? theme.spacing(4) : undefined,
  },
}));

export const Title = styled("h3")(({ theme }) => ({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  maxHeight: 28,
  alignItems: "center",
  WebkitLineClamp: 1,
  color: colors.neutral90,
  font: fonts.header3,
  overflow: "hidden",

  [theme.breakpoints.up("md")]: {
    maxHeight: 40,
  },
}));

export const BodyTextWrapper = styled("div")(({ theme }) => ({
  height: 120,

  [theme.breakpoints.up("md")]: {
    height: 78,
  },
}));

export const BodyText = styled("p")(() => ({
  font: fonts.bodyMedium,
  color: colors.neutral80,
}));

import React, { useEffect } from "react";
import * as Styled from "./AdSlot.styled";

const AdSlot: React.FC<{ id: string }> = ({ id }) => {
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    const loadAd = () => {
      if (window.loadAdvertSlot) {
        window.loadAdvertSlot(id).then(() => {
          console.log("loaded ad slot: ", id);
        });
      } else {
        timer = setTimeout(loadAd, 100);
      }
    };

    const lastEvent = window.eventBus.on("triggerAd", loadAd);

    if (lastEvent?.hasInitialAds) loadAd();

    return () => {
      if (timer) clearTimeout(timer);
      window.eventBus.off("triggerAd", loadAd);
    };
  }, [id]);

  return (
    <Styled.Container>
      <div id={id}></div>
    </Styled.Container>
  );
};

export default AdSlot;

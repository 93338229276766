import React, { ReactNode } from "react";
import { styled } from "@mui/material/";
import { fonts } from "@constants/cssVariables";

const ListItem = styled("li")({
  font: fonts.bodyMedium,
});

const RichTextListItem: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <ListItem>{children}</ListItem>;
};

export default RichTextListItem;

import React, { FC } from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import SocialMedia from "@navigation/SocialMedia";
import Subscription from "@navigation/Subscription";
import { useQuery } from "@tanstack/react-query";
import ErrorBoundary from "@common/ErrorBoundary/ErrorBoundary";
import { footerTalpaRightsText } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import * as Styled from "./Footer.styled";

const footerQueryKey = "footer";

const trackClick = (eventLabel: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.FOOTER,
    event_name: TEALIUM_EVENT_NAME.FOOTER_CLICK,
    event_label: eventLabel,
  });
};

const Footer: FC<{ footer?: Navigation | null }> = ({ footer }) => {
  const { socialMedia } = useHostConfig();

  const currentYear = new Date().getFullYear();

  const { data: items, error } = useQuery({
    queryKey: [footerQueryKey],
    queryFn: () => fetchByEndpoint<Navigation>("/api/navigation/footer"),
    refetchOnWindowFocus: false,
    initialData: footer,
    select: (data) => data?.items,
    enabled: !footer,
  });

  useNoticeError(error, { queryKey: footerQueryKey });

  if (!items) return null;

  return (
    <Styled.FooterWrapper>
      <Styled.ItemsWrapper>
        <Subscription />
        <Styled.SubmenuContainer>
          <Styled.SubmenuItems>
            {items.map((submenu) => (
              <React.Fragment key={submenu.id}>
                {submenu.type === "submenu" && (
                  <Styled.Submenu>
                    <Styled.SubmenuTitle>{submenu.title}</Styled.SubmenuTitle>
                    {submenu.items.map((submenuItem) => (
                      <React.Fragment key={submenuItem.id}>
                        {submenuItem.type !== "submenu" && (
                          <Link
                            key={submenuItem.id}
                            href={submenuItem.url}
                            target={submenuItem.target}
                            onClick={() => trackClick(submenuItem.title)}
                            suppressHydrationWarning={submenuItem.url.startsWith("#")}
                          >
                            <Styled.SubmenuItemTitle>{submenuItem.title}</Styled.SubmenuItemTitle>
                          </Link>
                        )}
                      </React.Fragment>
                    ))}
                  </Styled.Submenu>
                )}
              </React.Fragment>
            ))}
          </Styled.SubmenuItems>
          <Styled.MarketingItems>
            <Styled.Socials>
              <SocialMedia {...socialMedia} eventCategory={TEALIUM_EVENT_CATEGORY.FOOTER} />
            </Styled.Socials>
          </Styled.MarketingItems>
        </Styled.SubmenuContainer>
        <Styled.RightsText>&copy; {`${currentYear} ${footerTalpaRightsText}`}</Styled.RightsText>
      </Styled.ItemsWrapper>
    </Styled.FooterWrapper>
  );
};

const SafeFooter: FC<{ footer?: Navigation | null }> = (props) => (
  <ErrorBoundary onError={() => <></>}>
    <Footer {...props} />
  </ErrorBoundary>
);

export default SafeFooter;

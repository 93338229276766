import React from "react";
import useSearchValues from "@navigation/SearchModal/useSearchValues";
import { ItemsContainer, NavigationItem } from "@navigation/TopNavigation/TabBar/TabBar.styled";
import updateSearchParams from "@utils/navigation/updateSearchParams";
import { SearchModalTopBarContentWrapper } from "../SearchModalTopBar/SearchModalTopBar.styled";
import * as Styled from "./SearchModalTabBar.styled";

export enum SearchTabs {
  ALL = "",
  ARTICLES = "artikelen",
  VIDEOS = "videos",
}

export const searchTabValues = {
  [SearchTabs.ALL]: {
    queryParam: "",
    contentType: "",
    tabValue: "Alles",
  },
  [SearchTabs.ARTICLES]: {
    queryParam: "artikelen",
    contentType: "article",
    tabValue: "Artikelen",
  },
  [SearchTabs.VIDEOS]: {
    queryParam: "videos",
    contentType: "video",
    tabValue: "Video's",
  },
};

interface SearchModalTabBarProps {
  shouldAnimateTabBar: boolean;
  searchTermHasResults: boolean;
}

const SearchModalTabBar: React.FC<SearchModalTabBarProps> = ({ shouldAnimateTabBar, searchTermHasResults }) => {
  const { searchValue, tabValue } = useSearchValues();
  const selectedTab = tabValue || searchTabValues[SearchTabs.ALL].queryParam;

  const handleTabClick = (tab: string) => {
    updateSearchParams(searchValue, tab);
  };

  return (
    <Styled.AnimatedTabBarContainer isTabBarHidden={false} shouldAnimateTabBar={shouldAnimateTabBar}>
      <SearchModalTopBarContentWrapper>
        <ItemsContainer>
          {searchTermHasResults &&
            Object.values(SearchTabs)?.map((tab) => (
              <NavigationItem
                as="div"
                href={""}
                onClick={() => handleTabClick(tab)}
                data-text={searchTabValues[tab].tabValue}
                aria-current={selectedTab === searchTabValues[tab].queryParam}
                key={tab}
              >
                {searchTabValues[tab].tabValue}
              </NavigationItem>
            ))}
        </ItemsContainer>
      </SearchModalTopBarContentWrapper>
    </Styled.AnimatedTabBarContainer>
  );
};

export default SearchModalTabBar;

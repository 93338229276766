import { Skeleton, SxProps, Theme, styled } from "@mui/material";
import { colors } from "@constants/cssVariables";

const TextSkeletonWrapper = styled("div")(({ theme }) => ({
  height: 12,

  [theme.breakpoints.up("md")]: {
    height: 14,
  },
}));

const TextSkeleton: React.FC<{
  width?: string | number | Array<string | number>;
  height?: string | number | Array<string | number>;
  sx?: SxProps<Theme>;
}> = ({ width, sx, height }) => (
  <Skeleton
    data-testid="text-skeleton"
    sx={{ backgroundColor: colors.neutral10, width, height, ...sx }}
    variant="rounded"
    width="100"
  >
    <TextSkeletonWrapper />
  </Skeleton>
);

export default TextSkeleton;

const observerNotSupported = typeof IntersectionObserver === "undefined";
const listenerCallbacks = new WeakMap();

let observer: IntersectionObserver;

function handleIntersections(entries: IntersectionObserverEntry[]): void {
  entries.forEach((entry) => {
    if (listenerCallbacks.has(entry.target)) {
      const cb = listenerCallbacks.get(entry.target);
      const isVisible = entry.isIntersecting;
      cb({ isVisible });
    }
  });
}

function getIntersectionObserver(rootMargin?: string): IntersectionObserver {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: rootMargin || "0px",
      threshold: 0.1,
    });
  }
  return observer;
}

export function observe(target: Element, callback: (data: { isVisible: boolean }) => void, rootMargin?: string): void {
  if (observerNotSupported) {
    // Should we investigate a fallback?
    return;
  }
  observer = getIntersectionObserver(rootMargin);

  listenerCallbacks.set(target, callback);
  observer.observe(target);
}

export function unobserve(target: Element): void {
  if (observerNotSupported) return;
  listenerCallbacks.delete(target);
  observer.unobserve(target);
}

import React from "react";
import featureTooling from "./featureTooling";

interface IFeatureState<T> {
  enabled: boolean | null;
  variables: T | null;
  variant: string | null;
}

const getFeaturesState = <T = any>(featuresSlugs: string[]): IFeatureState<T>[] =>
  featuresSlugs.map((featureSlug) => ({
    enabled: featureTooling.isEnabled(featureSlug),
    variant: featureTooling.getVariant(featureSlug),
    variables: featureTooling.getVariables(featureSlug),
  }));

function useFeature<T = any>(featureSlug: string): IFeatureState<T>;
function useFeature<T = any>(...featuresSlugs: string[]): IFeatureState<T>[];
function useFeature<T = any>(...featuresSlugs: string[]): IFeatureState<T> | IFeatureState<T>[] {
  const [state, setState] = React.useState<IFeatureState<T>[]>(getFeaturesState(featuresSlugs));

  React.useEffect(() => {
    function onFeatureReset() {
      const newState: IFeatureState<T>[] = getFeaturesState(featuresSlugs);
      // Only update state when enabled or variant has changed
      if (
        newState.every((item, index) => item.enabled === state[index].enabled && item.variant === state[index].variant)
      )
        return;
      setState(newState);
    }

    window.eventBus.on("featureReset", onFeatureReset);

    return () => window.eventBus.off("featureReset", onFeatureReset);
  }, [featuresSlugs, state]);

  return featuresSlugs.length === 1 ? state[0] : state;
}

export default useFeature;

import Image from "next/image";
import Link from "next/link";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { SCALE_ANIMATION } from "@constants/consts";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";
import trackContentClick from "@utils/common/trackContentClick";

const SquareCollectionWrapperItems = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: theme.spacing(4),
  columnGap: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    columnGap: theme.spacing(4),
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

const SquareCollectionWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 0),
  },
}));

const SquareCollectionTitle = styled("p")(({ theme }) => ({
  font: fonts.header3,
  color: colors.neutral90,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(5),
  },
}));

const StyledAspectRationContainer = styled(AspectRatioContainer)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: 6,

  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
  },

  ...SCALE_ANIMATION,

  "&:after": {
    content: '""' as string,
    position: "absolute",
    inset: 0,
    zIndex: zIndex.base,
    background: gradients.fadeBlackBottomTop,
  },
}));

const SquareCollectionImage = styled(Image)(() => ({
  objectFit: "cover",
}));

const CardTitleWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: zIndex.baseControls,
  padding: 0,
  inset: `auto ${theme.spacing(4, 3, 4)}`,

  [theme.breakpoints.up("md")]: {
    inset: `auto ${theme.spacing(6, 4, 6)}`,
  },
}));

const Title = styled("p")(() => ({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  font: fonts.header3,
  color: colors.neutral0,
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
}));

const Subtitle = styled(Title)(() => ({
  font: fonts.header5,
}));

const SquareCollection: React.FC<{ title: string; items: Array<PageLink> }> = ({ title: sectionTitle, items }) => (
  <SquareCollectionWrapper>
    <SquareCollectionTitle>{sectionTitle}</SquareCollectionTitle>
    <SquareCollectionWrapperItems>
      {items.map((item, index) => (
        <Link key={item.id} href={item.url} target={item.target}>
          <StyledAspectRationContainer
            data-testid="square-collection-test-id"
            aspectRatio="1:1"
            onClick={() =>
              trackContentClick({
                position: index + 1,
                label: sectionTitle,
                ...item,
              })
            }
          >
            <SquareCollectionImage
              alt={item.title}
              src={item.imageUrl}
              fill
              sizes="(max-width: 900px) 50vw, (max-width: 1200px) 33vw, 25vw"
            />
            <CardTitleWrapper>
              {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
              <Title>{item.title}</Title>
            </CardTitleWrapper>
          </StyledAspectRationContainer>
        </Link>
      ))}
    </SquareCollectionWrapperItems>
  </SquareCollectionWrapper>
);

export default SquareCollection;

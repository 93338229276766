import { styled } from "@mui/material";
import MuiAccordion, { accordionClasses } from "@mui/material/Accordion";
import { colors, fonts } from "@constants/cssVariables";

export const Accordion = styled(MuiAccordion)({
  border: `2px solid ${colors.neutral10}`,
  borderTopWidth: 0,
  boxShadow: "none",
  "&:before": {
    content: "inherit",
  },
  [`&.${accordionClasses.expanded}`]: {
    borderTopWidth: 2,
  },
});

export const Summary = styled("h3")({
  font: fonts.header5,
});

export const Container = styled("div")({
  [`${Accordion}:first-of-type`]: {
    borderTopWidth: 2,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  [`${Accordion}.${accordionClasses.expanded} + ${Accordion}`]: {
    borderTopWidth: 2,
  },
});

import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Tealium from "@4tn/webx-analytics";
import { styled } from "@mui/material/styles";
import SocialMedia from "@navigation/SocialMedia";
import { useQuery } from "@tanstack/react-query";
import { CrossIcon, LeftArrowIcon, RightArrowIcon } from "@common/Icons";
import { SIDEBAR_MENU_BACK_BUTTON_LABEL, transitions } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { zIndex } from "@constants/zIndex";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import smoothScrollTo from "@utils/common/smoothScrollTo";
import { isSubmenu } from "@utils/navigation/navigation";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";

const hamburgerQueryKey = "hamburger";

const BackAndCloseContainer = styled("div")(({ theme }) => ({
  height: 56,
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    height: 64,
  },
}));

const AnimationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: 320,
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    width: 400,
  },
}));

const SidebarMenu = styled("div")<{ isHamburgerMenuOpen: boolean }>(({ isHamburgerMenuOpen, theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "fixed",
  top: 0,
  zIndex: zIndex.modal + 1,
  backgroundColor: colors.neutral90,
  width: 320,
  transition: transitions.hamburgerMenu,
  transform: isHamburgerMenuOpen ? "none" : "translateX(-320px)",

  [theme.breakpoints.up("md")]: {
    minWidth: 400,
    transform: isHamburgerMenuOpen ? "none" : "translateX(-400px)",
  },
}));

const Options = styled("div")<{ level: number }>(({ level, theme }) => ({
  display: "inline-flex",
  minWidth: 320,
  flexDirection: "column",
  alignItems: "flex-start",
  height: `calc(100% - ${level === 1 ? "202px" : "56px"})`,
  marginTop: theme.spacing(5),
  overflowY: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",

  [theme.breakpoints.up("md")]: {
    height: `calc(100% - ${level === 1 ? "211px" : "64px"})`,
    marginTop: theme.spacing(4.5),
    minWidth: 400,
  },
}));

const MenuItem = styled("div")(({ theme }) => ({
  width: 320,
  height: 50,
  position: "relative",
  display: "flex",
  justifycontent: "center",
  alignItems: "center",
  flexShrink: 0,

  "&:hover": {
    cursor: "pointer",
    backgroundColor: colors.primary,
  },

  [theme.breakpoints.up("md")]: {
    width: 400,
    height: 48,
  },
}));

const MenuItemTitle = styled("p")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.bodyLarge,
  width: 272,
  height: 26,
  margin: theme.spacing(3, 6),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(2.5, 8),
    width: 336,
    height: 28,
  },
}));

const BackContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  zIndex: zIndex.drawer,
  visibility: "hidden",
  display: "flex",
  position: "absolute",
  top: 16,
  left: 24,
  gap: theme.spacing(3),

  [theme.breakpoints.up("md")]: {
    top: 20,
    left: 32,
  },
}));

const Back = styled(LeftArrowIcon)({
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 24,
    height: 24,
  },
});

const BackText = styled("p")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.tab,
  padding: theme.spacing(0.5, 0),
}));

const Close = styled(CrossIcon)(({ theme }) => ({
  zIndex: zIndex.drawer,
  cursor: "pointer",
  position: "absolute",
  right: 24,
  top: 8,
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 40,
    height: 40,
  },

  [theme.breakpoints.up("md")]: {
    top: 12,
  },
}));

const RightArrow = styled(RightArrowIcon)(({ theme }) => ({
  cursor: "pointer",
  position: "absolute",
  right: 24,
  top: 12,
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 24,
    height: 24,
  },

  [theme.breakpoints.up("md")]: {
    right: 32,
  },
}));

const SocialMediaContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  display: "flex",
  height: 146,
  width: 320,
  bottom: 0,
  left: 0,
  backgroundColor: colors.neutral90,
  padding: theme.spacing(4, 6),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4, 8),
    width: 400,
  },
}));

const OptionsWrapper = styled("div")(() => ({
  position: "relative",
}));

const trackItemClick = (title: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.MENU,
    event_name: TEALIUM_EVENT_NAME.MENU_CLICK,
    event_label: title,
  });
};

interface HamburgerMenuProps {
  navigation?: Navigation | null;
}

const Hamburger: React.FC<HamburgerMenuProps> = ({ navigation }) => {
  const { socialMedia } = useHostConfig();
  const divRef = useRef<HTMLDivElement>(null);
  const backButtonButtonRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const { data: items, error } = useQuery({
    queryKey: [hamburgerQueryKey],
    queryFn: () => fetchByEndpoint<Navigation>("/api/navigation/hamburger-menu"),
    refetchOnWindowFocus: false,
    initialData: navigation,
    enabled: !navigation,
  });

  useNoticeError(error, { queryKey: hamburgerQueryKey });

  const [menus, setMenus] = useState<Navigation[]>(items ? [items] : []);
  const timerRefs = useRef<NodeJS.Timeout[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleBackButton = (shouldShow = true) => {
    if (!backButtonButtonRef.current) return;
    backButtonButtonRef.current.style.visibility = shouldShow ? "visible" : "hidden";
  };

  const handleCloseClick = () => {
    window.eventBus.dispatch("hamburgerMenu", { isOpen: false });
  };

  const handleBackClick = () => {
    if (!divRef.current) return;
    if (menus.length === 2) {
      toggleBackButton(false);
    }
    const targetPosition = divRef.current.offsetWidth * (menus.length - 2);
    smoothScrollTo(divRef.current, targetPosition, 300);
    const timer = setTimeout(setMenus, 300, (prev) => (prev.length > 1 ? prev.slice(0, -1) : prev));

    timerRefs.current.push(timer);
  };

  const handleMenuClick = (item: Submenu | NavigationLink) => {
    if (isSubmenu(item)) {
      toggleBackButton(true);
      setMenus((prev) =>
        [...prev, item].filter((item, index, array) => array.findIndex(({ id }) => id === item.id) === index)
      );
      return;
    }
    trackItemClick(item.title);
    if (item.target === "_blank") {
      window.open(item.url, "_blank");
      return;
    }
    router.push(item.url);
    handleCloseClick();
  };

  useEffect(() => {
    if (items) {
      setMenus([items]);
    }
  }, [items]);

  useEffect(() => {
    const onHamburgerMenu = (data: WebXEventParams["hamburgerMenu"]) => {
      setIsOpen(data.isOpen);
      document.body.classList.toggle("hamburger-menu-open", data.isOpen);
      if (data.isOpen) {
        document.body.style.setProperty("overflow-x", "hidden");
        document.documentElement.style.setProperty("overflow-x", "hidden");
      }

      if (data.isOpen === false && items) {
        toggleBackButton(false);
        document.documentElement.style.setProperty("overflow-x", "visible");
        const timer = setTimeout(() => {
          setMenus([items]);
          document.body.style.setProperty("overflow-x", "visible");
        }, 400);
        timerRefs.current.push(timer);
      }
    };

    window.eventBus.on("hamburgerMenu", onHamburgerMenu);
    return () => {
      window.eventBus.off("hamburgerMenu", onHamburgerMenu);
      timerRefs.current.forEach((timer) => clearTimeout(timer));
    };
  }, []);

  useEffect(() => {
    if (!divRef.current) return;
    const scrollToMenu = (event?: Event) => {
      if (!divRef.current) return;
      const targetPosition = divRef.current.offsetWidth * (menus.length - 1);
      smoothScrollTo(divRef.current, targetPosition, event ? 0 : 300);
    };
    scrollToMenu();
    if (!isOpen || menus.length === 1) return;

    window.addEventListener("resize", scrollToMenu);
    return () => {
      window.removeEventListener("resize", scrollToMenu);
    };
  }, [menus, divRef, isOpen]);

  if (!menus.length) return null;

  return (
    <SidebarMenu isHamburgerMenuOpen={isOpen}>
      <BackAndCloseContainer>
        <BackContainer ref={backButtonButtonRef} onClick={handleBackClick} role="button" aria-label="back">
          <Back />
          <BackText>{SIDEBAR_MENU_BACK_BUTTON_LABEL}</BackText>
        </BackContainer>
        <Close role="button" aria-label="close" onClick={handleCloseClick} />
      </BackAndCloseContainer>
      <AnimationContainer ref={divRef}>
        {menus.map((menu, index) => (
          <OptionsWrapper key={menu.id}>
            <Options level={index + 1}>
              {menu.items.map((item) => (
                <MenuItem role="menuitem" key={item.id} onClick={() => handleMenuClick(item)}>
                  <MenuItemTitle>{item.title}</MenuItemTitle>
                  {item.type === "submenu" && <RightArrow />}
                </MenuItem>
              ))}
            </Options>
            {index === 0 && (
              <SocialMediaContainer>
                <SocialMedia {...socialMedia} />
              </SocialMediaContainer>
            )}
          </OptionsWrapper>
        ))}
      </AnimationContainer>
    </SidebarMenu>
  );
};

export default Hamburger;

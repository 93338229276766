import { utcToZonedTime } from "date-fns-tz";
import { episodeUrlPrefix } from "@constants/consts";
import { getDateLabel } from "@utils/pageContent/dateLabel";
import secondsToISODuration from "./secondsToISODuration";

const timeZone = "Europe/Amsterdam";

const imagePrefix =
  "https://cldnr.prod.webx.talpa.digital/talpa-network/image/fetch/ar_16:9,c_fill,f_auto,g_face:auto,h_1080,w_auto,f_auto/";

class VideoStructuredData {
  "@context": "https://schema.org";
  "@type": "VideoObject";
  contentUrl?: string;
  embedUrl?: string;
  dateModified: string;
  uploadDate: string;
  duration: string;
  name: string;
  headline: string;
  description: string;
  thumbnail: {
    "@type": "ImageObject";
    contentUrl: string;
    width: string;
    height: string;
    dateCreated?: string;
    thumbnailUrl?: string;
  };
  thumbnailUrl: string;
  mainEntityOfPage: {
    "@context": "https://schema.org";
    "@type": "WebPage";
    additionalType: string;
    dateModified: string;
    datePublished: string;
    description: string;
    headline: string;
    name: string;
    url: string;
  };

  constructor(
    program: Program,
    { url, imageUrl, contentUrl, videoGuid }: { url: string; imageUrl: string; contentUrl?: string; videoGuid: string }
  ) {
    const uploadDate = program.added ? utcToZonedTime(new Date(program.added), timeZone).toISOString() : "";
    const dateModified = program.updated ? utcToZonedTime(new Date(program.updated), timeZone).toISOString() : "";

    this["@context"] = "https://schema.org";
    this["@type"] = "VideoObject";
    this.dateModified = dateModified;
    this.uploadDate = uploadDate;
    if (contentUrl) {
      this.contentUrl = contentUrl || "";
    } else {
      this.embedUrl = `https://embed.kijk.nl/video/${videoGuid}`;
    }
    this.duration = program.duration ? secondsToISODuration(program.duration) : "";

    this.name = `${program.series?.title}: Seizoen ${
      program.season?.title || program.season?.seasonNumber
    } Aflevering ${program.tvSeasonEpisodeNumber}`;
    this.headline = this.name;
    this.description = program.description;
    this.thumbnail = {
      "@type": "ImageObject",
      contentUrl: `${imagePrefix}${imageUrl}`,
      width: "1920",
      height: "1080",
      dateCreated: uploadDate,
    };
    this.thumbnailUrl = imageUrl;
    this.mainEntityOfPage = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      additionalType: "video_leaf",
      dateModified: this.dateModified,
      datePublished: this.uploadDate,
      description: this.description,
      headline: this.headline,
      name: this.name,
      url: url,
    };
  }
}

class SeriesStructuredData {
  "@context": "https://schema.org";
  "@type": "WebPage";

  description: string;
  genre: string;
  name: string;
  // publisher: { "@type": "Organization"; name: string; logo: {} };
  thumbnailUrl?: string;
  url: string;

  constructor(series: Series, { origin }: { origin: string }) {
    this["@context"] = "https://schema.org";
    this["@type"] = "WebPage";

    this.name = series.title;
    this.description = series.description;
    this.genre = series.displayGenre;

    const landscapeImage = series.imageMedia?.find((image) => image.label?.includes("landscape"));
    if (landscapeImage) {
      this.thumbnailUrl = `${imagePrefix}${landscapeImage.url}`;
      // Todo: We might need to set a fallback here
    }

    this.url = `${origin}/${episodeUrlPrefix}/${series.slug}`;
  }
}

export class Episode {
  videoGuid: string;
  title: string = "";
  subtitle: string = "";
  episodeTitle: string;
  description: string;
  updatedAt: string;
  dateLabel?: string;
  createdAt?: string;
  contentUrl?: string;
  imageUrl: string = "";
  seriesSlug: string = "";
  url: string;
  isAvailable: boolean;
  tvSeasonId?: string;
  duration: number = 0;
  videoStructuredData?: VideoStructuredData;
  seriesStructuredData?: SeriesStructuredData;
  constructor(
    program: Program,
    {
      origin,
      seriesTitle,
      includeStructuredData,
    }: { origin: string; seriesTitle?: string; includeStructuredData?: boolean }
  ) {
    this.videoGuid = program.guid;

    if (program.series) {
      this.title = seriesTitle || program.series.title;
      this.seriesSlug = program.series.slug;
    }
    if (program.season) {
      this.subtitle = `Seizoen ${program.season.title || program.season.seasonNumber}, aflevering ${
        program.tvSeasonEpisodeNumber
      }`;
    }
    this.episodeTitle = `Aflevering ${program.tvSeasonEpisodeNumber}`;
    this.description = program.description || "";
    this.updatedAt = program.updated ? utcToZonedTime(new Date(program.updated), timeZone).toISOString() : "";
    this.createdAt = program.added ? utcToZonedTime(new Date(program.added), timeZone).toISOString() : "";

    // Kijk Description Fallback
    if (this.description.match(/op KIJK/i)) this.description = this.description.replace(/ op KIJK/i, "");

    const [media] = program.media || [];
    this.isAvailable = !!media?.availabilityState;
    if (media) {
      this.dateLabel = getDateLabel(media.airedDateTime, { showDay: true, showTime: true });
    }

    const { file: contentUrl } = (program.sources || []).find((source) => source.type === "m3u8") || {};

    this.contentUrl = contentUrl;

    const landscapeImage = program.imageMedia?.find((image) => image.label?.includes("landscape"));
    if (landscapeImage) {
      this.imageUrl = landscapeImage.url;
      // Todo: We might need to set a fallback here
    }
    this.url = `${origin}/${episodeUrlPrefix}/${this.seriesSlug}/${this.videoGuid}`;
    this.tvSeasonId = program.tvSeasonId;
    if (program.duration) {
      this.duration = program.duration;
    }

    if (includeStructuredData) {
      this.videoStructuredData = new VideoStructuredData(program, {
        url: this.url,
        imageUrl: this.imageUrl,
        contentUrl: this.contentUrl,
        videoGuid: this.videoGuid,
      });
      if (program.series) {
        this.seriesStructuredData = new SeriesStructuredData(program.series, { origin });
      }
    }
  }
}

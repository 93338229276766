import Image from "next/image";
import { styled } from "@mui/material";
import IconButton from "@common/Buttons/IconButton";
import { colors, fonts } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const LinkListItemContentContainer = styled("div")<{ isSponsoredLinkListItem?: boolean }>(
  ({ isSponsoredLinkListItem, theme }) => ({
    display: "flex",
    alignItems: isSponsoredLinkListItem ? "flex-start" : "center",
    gap: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      ...(isSponsoredLinkListItem && {
        padding: theme.spacing(4.5, 0),
        borderTop: `1px solid ${colors.neutral10}`,
        borderBottom: `1px solid ${colors.neutral10}`,
      }),
    },
  })
);

export const LinkListItemImageContainer = styled("div")(() => ({
  position: "relative",
  width: 115,
  minWidth: 115,
  height: 76,
}));

export const LinkListItemImage = styled(Image)(() => ({
  objectFit: "cover",
  borderRadius: 6,
}));

export const PlayIcon = styled(IconButton)(() => ({
  position: "absolute",
  left: 85,
  top: 46,
  zIndex: zIndex.base,
}));

export const LinkListItemTextContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 0, 3),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(1, 0),
  },
}));

export const SponsoredLabel = styled("div")(({ theme }) => ({
  font: fonts.bodySmall,
  color: colors.neutral30,
  paddingBottom: theme.spacing(1),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const LinkListSponsoredContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: 15,
  gap: theme.spacing(6),
}));

export const LinkListSponsoredItemText = styled("div")(() => ({
  font: fonts.bodyExtraSmall,
  color: colors.neutral40,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const LinkListSponsoredItemLogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  minWidth: 50,
  height: 20,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const LinkListSponsoredItemLogo = styled(Image)(() => ({
  maxWidth: "100%",
  height: "auto",
  objectFit: "contain",
}));

export const LinkListItemTitle = styled("p")(({ theme }) => ({
  width: "100%",
  maxHeight: 76,
  font: fonts.header6,
  color: colors.neutral80,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

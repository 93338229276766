import React, { ReactNode } from "react";
import { styled } from "@mui/material";
import { colors } from "@constants/cssVariables";
import { RichText } from "@constants/richText";

export const headingMap: { [key: string]: string } = {
  [RichText.HEADING_2]: "2",
  [RichText.HEADING_3]: "3",
  [RichText.HEADING_4]: "4",
  [RichText.HEADING_5]: "5",
  [RichText.HEADING_6]: "6",
};

const StyledHeading = styled("h3")<{ level: string }>(({ level }) => ({
  font: `var(--font-header-${level})`,
  color: colors.neutral90,
}));

const RichTextHeading: React.FC<{ children: ReactNode; type?: string }> = ({ children, type }) => {
  if (!type) return null;

  const level = headingMap[type];

  return (
    <StyledHeading as={`h${headingMap[type]}` as "h2" | "h3" | "h4" | "h5" | "h6"} level={level}>
      {children}
    </StyledHeading>
  );
};

export default RichTextHeading;

import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { InfoIcon } from "@common/Icons";
import { colors, fonts } from "@constants/cssVariables";

export const RelativeAspectRatio = styled(AspectRatioContainer)({
  position: "relative",
});

export const MessageContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  inset: 0,
  padding: theme.spacing(4),
  borderRadius: 8,
  backgroundColor: colors.neutral50,
  color: colors.neutral0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  gap: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export const AlertIcon = styled(InfoIcon)(({ theme }) => ({
  border: `2px solid ${colors.neutral0}`,
  width: 70,
  height: 70,
  padding: theme.spacing(3),
  borderRadius: 70,
  svg: {
    width: 40,
    height: 40,
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const Header = styled("h2")(({ theme }) => ({
  font: fonts.header3,
  [theme.breakpoints.down("md")]: {
    font: fonts.header4,
  },
  [theme.breakpoints.down("sm")]: {
    font: fonts.header5,
  },
}));

export const BodyText = styled("p")(({ theme }) => ({
  font: fonts.bodyLarge,
  [theme.breakpoints.down("md")]: {
    font: fonts.bodyMedium,
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

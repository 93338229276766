import isObject from "./isObject";

export default function deepMerge<T>(target: T, source: Partial<T>): T {
  if (Array.isArray(target) && Array.isArray(source)) {
    return [...target, ...source] as T;
  }

  if (isObject(target) && isObject(source)) {
    const merged = { ...target } as { [key: string]: any };

    for (const key in source) {
      if (key in target && typeof target[key] === "object" && typeof source[key] === "object") {
        merged[key] = deepMerge(target[key] as object, source[key] as Partial<object>);
      } else {
        merged[key] = source[key];
      }
    }

    return merged as T;
  }

  return source === undefined ? target : (source as T);
}

import { useEffect, useState } from "react";
import Link from "next/link";
import { NoSsr } from "@mui/material";
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, TwitterEmbed } from "react-social-media-embed";
import { PrimaryButton } from "@common/Buttons";
import SocialEmbedSkeleton from "@pageContent/common/Skeletons/SocialEmbedSkeleton";
import { BUTTON_SIZE } from "@constants/button";
import {
  SocialEmbedItemType,
  getNoConsentSocialEmbedMessageText,
  noConsentSocialEmbedButtonText,
  noConsentSocialEmbedMessageTitle,
  socialMediaConsentCategory,
} from "@constants/socialEmbed";
import * as StyledTextFrame from "../TextFrame/TextFrame.styled";
import * as Styled from "./SocialEmbed.styled";

interface SocialEmbedProps {
  embedType: SocialEmbedItemType;
  embedValue: string;
}

const SocialEmbed: React.FC<SocialEmbedProps> = ({ embedValue, embedType }) => {
  if (!embedValue || !embedType) return null;

  if (embedType === SocialEmbedItemType.FACEBOOK) {
    return <FacebookEmbed data-testid={embedType} url={embedValue} width={"100%"} />;
  }

  if (embedType === SocialEmbedItemType.INSTAGRAM) {
    return <InstagramEmbed data-testid={embedType} url={embedValue} width={"100%"} />;
  }

  if (embedType === SocialEmbedItemType.TWITTER) {
    return <TwitterEmbed data-testid={embedType} url={embedValue} width={"100%"} />;
  }

  if (embedType === SocialEmbedItemType.TIKTOK) {
    return <TikTokEmbed data-testid={embedType} url={embedValue} width={"100%"} />;
  }

  return null;
};

const NoConsentSocialEmbed: React.FC<{ noConsentMessage: string }> = ({ noConsentMessage }) => {
  return (
    <Styled.NoConsentWrapper>
      <StyledTextFrame.Title>{noConsentSocialEmbedMessageTitle}</StyledTextFrame.Title>
      <p>{noConsentMessage}</p>
      <PrimaryButton component={Link} size={BUTTON_SIZE.MEDIUM} href="#consent">
        {noConsentSocialEmbedButtonText}
      </PrimaryButton>
    </Styled.NoConsentWrapper>
  );
};

const SocialEmbedContainer: React.FC<SocialEmbedProps> = (props) => {
  const [noConsentMessage, setNoConsentMessage] = useState<string | null>(null);

  useEffect(() => {
    const onSocialConsentChange = ({
      consentGroups,
      isConsentModalClosed,
    }: {
      consentGroups: string;
      isConsentModalClosed: boolean;
    }) => {
      // If consent modal isn't closed, noConsentMessage should remain null in order to display the skeleton
      if (!isConsentModalClosed) return;
      // Otherwise display the appropriate version of the component depending on whether the consent was given or not
      if (consentGroups && consentGroups.includes(socialMediaConsentCategory)) {
        setNoConsentMessage("");
      } else {
        setNoConsentMessage(getNoConsentSocialEmbedMessageText(props.embedType));
      }
    };

    window.eventBus.on("consentChange", onSocialConsentChange, true);

    return () => {
      window.eventBus.off("consentChange", onSocialConsentChange);
    };
  }, [props.embedType]);

  if (noConsentMessage === null) {
    // Skeleton should only be shown in cases when consent has not been previously given and the consent window is open
    return <SocialEmbedSkeleton />;
  }

  if (noConsentMessage) {
    return <NoConsentSocialEmbed noConsentMessage={noConsentMessage} />;
  }

  return (
    <NoSsr>
      <Styled.Wrapper data-testid="social-embed">
        <SocialEmbed {...props} />
      </Styled.Wrapper>
    </NoSsr>
  );
};

export default SocialEmbedContainer;

import React, { ChangeEvent, useEffect, useState } from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import { AudioPlayerType, ViewType, audioIconLabels } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import featureTooling, { FeatureSlugs, useFeature } from "@utils/common/featureTooling";
import audioPlayerManager from "../../AudioPlayerManager/AudioPlayerManager";
import * as Styled from "./IconSection.styled";

export const volumeIconDataTestId = "volumeIconDataTestId";
export const volumeMutedIconDataTestId = "volumeMutedIconDataTestId";
export const volumeBarDataTestId = "volumeBarDataTestId";
export const whatsAppIconAriaLabel = "Contact opnemen met de studio";

const IconWrapper: React.FC<{
  label: string;
  inAudioDashboard: boolean;
  children: React.ReactNode;
}> = ({ label, children, inAudioDashboard }) => {
  return inAudioDashboard ? (
    <Styled.IconWrapper>
      {children}
      <Styled.IconLabel>{label}</Styled.IconLabel>
    </Styled.IconWrapper>
  ) : (
    children
  );
};

const IconSection = ({
  inAudioDashboard,
  videoId,
  isVideoPlaying,
  playerType,
}: {
  inAudioDashboard: boolean;
  videoId?: string;
  isVideoPlaying?: boolean;
  playerType?: AudioPlayerType;
}) => {
  const [volume, setVolume] = useState(audioPlayerManager.isMuted ? 0 : audioPlayerManager.volume);
  const { variables: featureVariables } = useFeature<{ whatsappUrl: string }>(FeatureSlugs.CONTACT_STUDIO);

  useEffect(() => {
    const handlePlayerVolume = (event: AudioPlayerEventParams["playerVolume"]) => {
      setVolume(event.volume);
    };
    const handleMuteToggle = (event: AudioPlayerEventParams["mute"]) => {
      setVolume(event.muted ? 0 : audioPlayerManager.volume);
    };
    audioPlayerManager.on("playerVolume", handlePlayerVolume);
    audioPlayerManager.on("mute", handleMuteToggle);
    return () => {
      audioPlayerManager.off("playerVolume", handlePlayerVolume);
      audioPlayerManager.off("mute", handleMuteToggle);
    };
  }, []);

  const handleVolumeMute = () => {
    if (volume) {
      audioPlayerManager.mute();
      return;
    }
  };

  const handleVolumeUnmute = () => {
    audioPlayerManager.volumeChange(audioPlayerManager.volume);
  };

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    audioPlayerManager.volumeChange(Number(event.target.value));
  };

  const handleVideoPlayback = (isPlaying: boolean) => {
    isPlaying && (playerType === AudioPlayerType.TRITON ? audioPlayerManager.stop() : audioPlayerManager.pause());
    window.eventBus.dispatch("radioLiveVideo", { isPlaying });
  };

  const handleOpenWhatsapp = () => {
    Tealium.link({
      event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
      event_name: TEALIUM_EVENT_NAME.PLAYER_CLICK,
      event_label: TEALIUM_EVENT_LABEL.CONTACT_STUDIO,
    });
  };

  return (
    <Styled.IconSectionContainer>
      {videoId && (
        <IconWrapper label={audioIconLabels.VIDEO_ICON} inAudioDashboard={inAudioDashboard}>
          {!isVideoPlaying && <Styled.StyledVideoIcon onClick={() => handleVideoPlayback(true)} />}
          <Styled.StyledVideoIconFilled onClick={() => handleVideoPlayback(false)} isVideoPlaying={!!isVideoPlaying} />
        </IconWrapper>
      )}
      {featureVariables?.whatsappUrl && (
        <IconWrapper label={audioIconLabels.WHATSAPP_ICON} inAudioDashboard={inAudioDashboard}>
          <Link
            href={featureVariables.whatsappUrl}
            onClick={handleOpenWhatsapp}
            target="_blank"
            aria-label={whatsAppIconAriaLabel}
          >
            <Styled.StyledWhatsAppIcon />
          </Link>
        </IconWrapper>
      )}
      <IconWrapper label={audioIconLabels.VOLUME_ICON} inAudioDashboard={inAudioDashboard}>
        {featureTooling.attributes.device !== ViewType.MOBILE && (
          <Styled.VolumeControlsContainer volumePercentage={`${(volume * 100).toString()}%`}>
            {!!volume && <Styled.VolumeIcon onClick={handleVolumeMute} data-testid={volumeIconDataTestId} />}
            <Styled.VolumeMutedIcon
              onClick={handleVolumeUnmute}
              isVolumeMuted={!volume}
              data-testid={volumeMutedIconDataTestId}
            />
            <Styled.VolumeBarContainer>
              <Styled.VolumeBar
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                data-testid={volumeBarDataTestId}
              />
            </Styled.VolumeBarContainer>
          </Styled.VolumeControlsContainer>
        )}
      </IconWrapper>
      <IconWrapper label={audioIconLabels.POP_OUT_ICON} inAudioDashboard={inAudioDashboard}>
        <Styled.StyledPopOutIcon />
      </IconWrapper>
    </Styled.IconSectionContainer>
  );
};

export default IconSection;

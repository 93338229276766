import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { AudioPlayerType, IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import audioPlayerManager from "../../AudioPlayerManager/AudioPlayerManager";
import * as Styled from "./MainPlayerControls.styled";

const MainPlayerControls: React.FC<{
  station: AudioStationEntry;
  playerType: AudioPlayerType;
  playbackState: AudioPlayerState;
}> = ({ station, playerType, playbackState }) => {
  const isAudioPlayerActive = playbackState === "playing";

  const handlePlayIconClicked = () => {
    if ((playbackState === "stop" || playbackState === "completed") && station.mountSource) {
      if (audioPlayerManager.media?.id !== station.id) audioPlayerManager.load(station);
      audioPlayerManager.start(station.mountSource);
    } else if (playbackState === "pause") {
      audioPlayerManager.resume();
    }
  };

  return (
    <Styled.MainPlayerControlsContainer>
      {isAudioPlayerActive && playerType === AudioPlayerType.WEB && (
        <Styled.StyledIconButton
          icon={IconEnum.PAUSE_THICK}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={() => audioPlayerManager.pause()}
          aria-label="pause"
        />
      )}
      {isAudioPlayerActive && playerType === AudioPlayerType.TRITON && (
        <Styled.StyledIconButton
          icon={IconEnum.STOP_FILLED}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={() => audioPlayerManager.stop()}
          aria-label="stop"
        />
      )}
      {!isAudioPlayerActive && (
        <Styled.StyledIconButton
          icon={playbackState === "loading" ? IconEnum.SPINNER : IconEnum.PLAY_ICON_FILLED}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={handlePlayIconClicked}
          aria-label="play"
        />
      )}
    </Styled.MainPlayerControlsContainer>
  );
};

export default MainPlayerControls;

import { TableCell, TableRow, styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";
import * as StyledParagraph from "../RichTextParagraph/RichTextParagraph.styled";

export const Row = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: colors.neutral10,
  },
});

export const TableHeaderCell = styled(TableCell)({
  [`${StyledParagraph.Paragraph}`]: {
    font: fonts.bodyMediumStrong,
  },
});

export const FilterInput = styled("input")(({ theme }) => ({
  flex: 1,
  backgroundColor: colors.neutral10,
  color: colors.neutral50,
  font: fonts.bodyMedium,
  borderRadius: 100,
  padding: theme.spacing(3.5, 6),
  border: "none",
  outline: "none",
  width: "100%",

  "::placeholder": {
    color: colors.neutral40,
    opacity: 1, // Firefox adds a lower opacity to the placeholder
  },

  [theme.breakpoints.up("md")]: {
    width: 456,
  },
}));

import Tealium from "@4tn/webx-analytics";
import { NOW_LIVE } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { Schedule } from "@utils/audio/dataModels";
import * as Styled from "../RadioSchedule.styled";

const trackClick = (position: number, showName: string) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_label: TEALIUM_EVENT_LABEL.RADIO_SCHEDULE,
    event_value: position,
    content_name: showName,
  });
};

const RadioScheduleItem: React.FC<Schedule & { position: number }> = ({
  title,
  showTime,
  isLive,
  thumbnailUrl,
  position,
}) => {
  return (
    // temporary until we have the show page
    <Styled.ItemContainer href={"/"} onClick={() => trackClick(position, title)}>
      <Styled.TextContainer>
        <Styled.TimeAndTitleContainer>
          <Styled.ShowTime>{showTime}</Styled.ShowTime>
          <Styled.ShowTitle>{title}</Styled.ShowTitle>
        </Styled.TimeAndTitleContainer>
        {isLive && <Styled.LiveLabel>{NOW_LIVE}</Styled.LiveLabel>}
      </Styled.TextContainer>
      {thumbnailUrl && (
        <Styled.AspectRatio aspectRatio="4:3">
          <Styled.ImageBackground />
          <Styled.ShowImage fill src={thumbnailUrl} alt={title} sizes="(max-width: 900px) 300px, 400px" />
        </Styled.AspectRatio>
      )}
    </Styled.ItemContainer>
  );
};

export default RadioScheduleItem;

import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const cloudinaryLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  const params = ["f_auto", "c_limit", `w_${width}`, `q_${quality || "auto"}`];

  if (src.startsWith("/")) return `${src}?w=${width}`;

  return `${publicRuntimeConfig.cloudinaryDomain}/image/fetch/${params.join(",")}/${src}`;
};

export default cloudinaryLoader;

import { FC, ReactNode } from "react";
import Link from "next/link";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { Button } from "@common/Buttons";
import RichText from "@pageContent/RichText";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { RootNode } from "@typings/richText";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./HighlightedItem.styled";

const HighlightedItemContainer: FC<{
  title: string;
  target?: TargetFields;
  children?: ReactNode;
}> = ({ title, target, children }) => {
  const onLinkClick = () => {
    if (!target) return;
    trackContentClick({
      label: title,
      ...target,
    });
  };

  return target ? (
    <Link href={target.url} target={target.target} onClick={onLinkClick}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export interface HighlightedItemProps {
  title: string;
  imageUrl: string;
  target?: TargetFields;
  text?: object;
}

const HighlightedItem: React.FC<HighlightedItemProps> = ({ title, imageUrl, target, text }) => {
  return (
    <HighlightedItemContainer title={title} target={target}>
      <Styled.HighlightedItemContainer>
        <Styled.HighlightedItemImageContainer>
          <AspectRatioContainer aspectRatio="16:9">
            <Styled.HighlightedItemImageGradient />
            <Styled.HighlightedItemImage src={imageUrl} alt={title} fill sizes="(max-width: 1200px) 100vw, 820px" />
          </AspectRatioContainer>
        </Styled.HighlightedItemImageContainer>
        <Styled.HighlightedItemMaskWrapper>
          <Styled.HighlightedItemMask>
            <Styled.HighlightedItemTitle>{title}</Styled.HighlightedItemTitle>
            {text && <RichText richText={text as RootNode} />}
            {target && (
              <Button component={"div"} variant={VARIANT.OUTLINE} size={BUTTON_SIZE.LARGE}>
                {target.title}
              </Button>
            )}
          </Styled.HighlightedItemMask>
        </Styled.HighlightedItemMaskWrapper>
      </Styled.HighlightedItemContainer>
    </HighlightedItemContainer>
  );
};

export default HighlightedItem;

export const TODAY = "Vandaag";
export const TOMORROW = "Morgen";
export const YESTERDAY = "Gisteren";
export const TIMEZONE = "Europe/Amsterdam";
export enum Dates {
  "jan",
  "feb",
  "mrt",
  "apr",
  "mei",
  "juni",
  "juli",
  "aug",
  "sep",
  "okt",
  "nov",
  "dec",
}

export default function isProdEnvironment(): boolean {
  const isServer = typeof window === "undefined";
  if (!isServer) {
    return false;
  }

  const env = process.env.ENV_NAME as HostConfiguration["env"];
  const mfeName = process.env.MFE_NAME as string;

  return env === "prod" && mfeName !== "canary";
}

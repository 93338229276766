import { ReactNode, useState } from "react";
import { intervalToDuration } from "date-fns";
import { IconEnum } from "@constants/consts";
import Label from "../Label";

const zeroPad = (num: number): string => String(num).padStart(2, "0");

const getDurationLabel = (sec: number): string => {
  try {
    const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: sec * 1000 }) as {
      hours: number;
      minutes: number;
      seconds: number;
    };
    if (hours === 0 && minutes === 0 && seconds === 0) return "";
    if (hours) return `${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    return `${minutes}:${zeroPad(seconds)}`;
  } catch {
    return "";
  }
};

interface DurationProps {
  icon?: IconEnum;
  iconTestId?: string;
  duration: number;
  children?: ReactNode;
  className?: any;
}
const Duration: React.FC<DurationProps> = ({ icon, iconTestId, duration, className }) => {
  const [durationLabel] = useState(getDurationLabel(duration));
  return durationLabel ? (
    <Label icon={icon} iconTestId={iconTestId} text={durationLabel} className={className} />
  ) : null;
};

export default Duration;

import { styled } from "@mui/material/styles";
import { transitions } from "@constants/consts";

const ContentContainer = styled("div")(({ theme }) => ({
  position: "relative",
  transition: transitions.hamburgerMenu,
  [theme.breakpoints.up("lg")]: {
    // Subtracts footer and top/tab bar heights from viewport height
    minHeight: "calc(100vh - 740px - 120px)",
  },
}));

export default ContentContainer;

import { useEffect, useMemo, useState } from "react";
import { GlobalStyles as MuiGlobalStyles, ThemeProvider, createTheme } from "@mui/material";
import { FooterWrapper } from "@navigation/Footer/Footer.styled";
import * as StyledTabBar from "@navigation/TopNavigation/TabBar/TabBar.styled";
import * as StyledTopBar from "@navigation/TopNavigation/TopBar/TopBar.styled";
import { AudioDashboardContainer } from "@audio/AudioDashboard/AudioDashboard.styled";
import { AudioPlayerContainer } from "@audio/AudioPlayer.styled";
import ContentContainer from "@common/ContentContainer/ContentContainer";
import TopAndTabBarContainer from "@common/TopAndTabBarContainer";

const smartBannerHeight = 80;

export const GlobalStyles = () => {
  const [smartBannerVisible, setSmartBannerVisible] = useState(false);
  const [inDashboardView, setInDashboardView] = useState(false);

  useEffect(() => {
    const onSmartBanner = (data: WebXEventParams["smartBanner"]) => setSmartBannerVisible(data.visible);
    window.eventBus.on("smartBanner", onSmartBanner);

    return () => {
      window.eventBus.off("smartBanner", onSmartBanner);
    };
  }, []);

  useEffect(() => {
    const onDashboardInView = (data: WebXEventParams["audioDashboard"]) => setInDashboardView(data.visible);
    window.eventBus.on("audioDashboard", onDashboardInView);

    return () => {
      window.eventBus.off("audioDashboard", onDashboardInView);
    };
  }, []);

  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        body: {
          "&.hamburger-menu-open": {
            [`${ContentContainer}, ${TopAndTabBarContainer}, ${FooterWrapper}, ${AudioPlayerContainer}`]: {
              transform: "translateX(320px)",

              [theme.breakpoints.up("md")]: {
                transform: "translateX(400px)",
              },
            },
            [`${AudioPlayerContainer}`]: {
              [theme.breakpoints.up("md")]: {
                ...(inDashboardView && {
                  transform: `translateY(100%)`,
                }),
              },
            },
          },
          ...(smartBannerVisible
            ? {
                paddingTop: 112 + smartBannerHeight,
                [theme.breakpoints.up("md")]: {
                  paddingTop: 120 + smartBannerHeight,
                },
                [`${StyledTopBar.TopBarContainer}, ${StyledTabBar.TabBarContainer}`]: {
                  top: smartBannerHeight,
                },
                ".jwplayer.custom-floating:not(.jw-flag-pip)": {
                  top: 56 + smartBannerHeight,
                },
              }
            : {
                "&:not(.in-app)": {
                  paddingTop: 112,
                  [theme.breakpoints.up("md")]: {
                    paddingTop: 120,
                  },
                },
              }),
        },
      })}
    />
  );
};

const Theme: React.FC<{ children: React.ReactNode; designTokens?: DesignTokens }> = ({ children, designTokens }) => {
  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1440,
          },
        },
        spacing: 4,
        palette: {
          primary: {
            // light?:
            main: designTokens?.colors.primary100 || "#000",
            // dark?: string;
            contrastText: designTokens?.colors.neutral0 || "#FFF",
          },
        },
      }),
    [designTokens]
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;

import { useEffect } from "react";
import { ErrorCodeMessages } from "@constants/newRelic";

const isServer = typeof window === "undefined";

const getWarningGroup = (errorCode: number): string => {
  switch (true) {
    case errorCode >= 303200 && errorCode <= 303230:
      return ErrorCodeMessages.PLAY_ATTEMPT_FAIL;
    case errorCode >= 302001 && errorCode <= 302611:
      return ErrorCodeMessages.RELATED;
    case errorCode >= 301121 && errorCode <= 305107:
      return ErrorCodeMessages.WARNING_LOADING_JAVASCRIPT_COMPONENTS;
    case errorCode >= 306001 && errorCode <= 306009:
      return ErrorCodeMessages.CAPTIONS;
    case errorCode >= 307000 && errorCode <= 307012:
      return ErrorCodeMessages.VR;
    case errorCode >= 308000 && errorCode <= 308640:
      return ErrorCodeMessages.LOCALIZATION;
    case errorCode >= 330000 && errorCode <= 339000:
      return ErrorCodeMessages.WARNING_MEDIA_PLAYBACK;
    case errorCode === 350000:
      return ErrorCodeMessages.CASTING;
    default:
      return ErrorCodeMessages.UNKNOWN_WARNING;
  }
};

const getErrorGroup = (errorCode: number): string => {
  switch (true) {
    case errorCode >= 100000 && errorCode <= 100014:
      return ErrorCodeMessages.SETUP_ERRORS_MISCELLANEOUS;
    case errorCode >= 102630 && errorCode <= 102700:
      return ErrorCodeMessages.EMPTY_PLAYLIST;
    case errorCode >= 102000 && errorCode <= 102621:
      return ErrorCodeMessages.PLAYLIST_PARSING;
    case errorCode >= 101100 && errorCode <= 104153:
      return ErrorCodeMessages.SETUP_ERRORS_LOADING_JAVASCRIPT_COMPONENTS;
    case errorCode === 200001:
      return ErrorCodeMessages.PLAYER_ERROR_MISCELLANEOUS;
    case errorCode >= 202000 && errorCode <= 202630:
      return ErrorCodeMessages.LOADING_NEW_PLAYLIST;
    case errorCode >= 203000 && errorCode <= 203640:
      return ErrorCodeMessages.PLAYLIST_ITEM;
    case errorCode >= 204100 && errorCode <= 204154:
      return ErrorCodeMessages.PLAYER_ERROR_LOADING_JAVASCRIPT_COMPONENTS;
    case errorCode >= 210000 && errorCode <= 214000:
      return ErrorCodeMessages.MEDIA_PLAYBACK_FLASH;
    case errorCode >= 220001 && errorCode <= 226599:
      return ErrorCodeMessages.MEDIA_PLAYBACK_HTML;
    case errorCode >= 230000 && errorCode <= 239000:
      return ErrorCodeMessages.MEDIA_PLAYBACK_HLS;
    case errorCode >= 240000 && errorCode <= 340000:
      return ErrorCodeMessages.MEDIA_PLAYBACK_SHAKA;
    default:
      return ErrorCodeMessages.UNKNOWN_ERROR;
  }
};

interface VideoError extends Error {
  code: number;
  type: string;
  key: string;
  sourceError: { details: string; response: { code: number } };
}

export function noticeError(
  error: Error,
  customAttributes: { [key: string]: string | number | boolean | undefined | null } = {}
): void {
  if (isServer || !window.newrelic) return;

  window.newrelic.noticeError(error, customAttributes);
}

export function noticeVideoError(error: VideoError, playlistItem: PlayListItem | null = null): void {
  const customErrorAttributes: { [key: string]: string | number | boolean | undefined | null } = {
    error_code: error.code.toString(),
    error_type: error.type,
    error_key: error.key,
    error_details: null,
    error_response_code: false,
    error_group: "",
    media_videotype: null,
    media_id: null,
  };

  if (playlistItem) {
    customErrorAttributes.media_id = playlistItem.metadata.media_id;
    customErrorAttributes.media_videotype = playlistItem.metadata.media_videotype;
  }

  if (customErrorAttributes && error.sourceError) {
    if (error.sourceError.details) {
      customErrorAttributes.error_details = error.sourceError.details;
    }
    if (error.sourceError.response?.code) {
      customErrorAttributes.error_response_code = error.sourceError.response.code.toString();
    }
  }

  if (error.type === "error" || error.type === "setupError") {
    customErrorAttributes.error_group = getErrorGroup(error.code);
  } else if (error.type === "warning") {
    customErrorAttributes.error_group = getWarningGroup(error.code);
  }

  noticeError(error, customErrorAttributes);
}

// Used for useQuery/useInfiniteQuery error instances
export const useNoticeError = (
  error: unknown,
  customAttributes: Record<string, string | number | boolean | undefined>
): void => {
  useEffect(() => {
    if (error) noticeError(error as Error, customAttributes);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const setCustomAttributes = (data: { [key: string]: string | boolean | number | null }): void => {
  if (isServer || !window.newrelic) return;

  Object.keys(data).forEach((key) => {
    window.newrelic.setCustomAttribute(key, data[key]);
  });
};

export function customInteraction(msg: string): void {
  if (isServer || !window.newrelic) return;

  window.newrelic.interaction().actionText(msg).save();
}

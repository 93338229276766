import { styled } from "@mui/material";

export const RichTextWrapper = styled("div")(({ theme }) => ({
  [`& > * + *`]: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
    },
  },
}));

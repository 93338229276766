import { styled } from "@mui/material/styles";
import { transitions } from "@constants/consts";
import { zIndex } from "@constants/zIndex";

const TopAndTabBarContainer = styled("div")(() => ({
  position: "fixed",
  top: 0,
  transition: transitions.hamburgerMenu,
  width: "100%",
  zIndex: zIndex.appBar - 1,
}));

export default TopAndTabBarContainer;

import Image from "next/image";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { ButtonContainer } from "@common/Buttons";
import { ButtonText } from "@common/Buttons/Button/Button";
import { RichTextWrapper } from "@pageContent/RichText/RichText.styled";
import { Paragraph } from "@pageContent/RichText/RichTextParagraph/RichTextParagraph.styled";
import { colors, fonts } from "@constants/cssVariables";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: theme.spacing(4),
  borderBottom: `1px solid ${colors.neutral10}`,
}));

export const Advert = styled("span")(({ theme }) => ({
  font: fonts.bodySmall,
  color: colors.neutral30,
  textAlign: "center",
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${colors.neutral10}`,
  marginBottom: theme.spacing(4),
  width: "100%",
}));

export const CardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  flexDirection: "column",

  [`${ButtonContainer}`]: {
    height: 40,
    padding: theme.spacing(3),
    maxWidth: 200,
    overflow: "hidden",
  },

  [`${ButtonText}`]: {
    font: fonts.buttonMedium,
  },

  [`${Paragraph}`]: {
    font: fonts.bodySmall,
  },

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",

    [`${ButtonContainer}`]: {
      padding: theme.spacing(3, 4),
      maxWidth: 275,
    },
  },
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  width: "100%",
  position: "relative",

  img: {
    objectFit: "cover",
    borderRadius: 6,
  },

  [theme.breakpoints.up("sm")]: {
    width: 230,
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

export const CardContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [`${RichTextWrapper}`]: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    alignItems: "center",
    WebkitLineClamp: 3,
    overflow: "hidden",

    [theme.breakpoints.up("sm")]: {
      WebkitLineClamp: 2,
    },
  },
}));

export const AdTitle = styled("h6")(({ theme }) => ({
  font: fonts.header6,
  color: colors.neutral80,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  marginBottom: theme.spacing(1),
}));

export const SponsoredLabel = styled("p")(() => ({
  font: fonts.bodyExtraSmall,
  color: colors.neutral40,
  overflow: "hidden",
  maxWidth: 145,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  alignItems: "center",
  WebkitLineClamp: 2,
}));

export const SponsoredLogoContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: 50,
  height: 20,
}));

export const SponsoredLogo = styled(Image)(() => ({
  maxWidth: "100%",
  height: "auto",
  objectFit: "contain",
  objectPosition: "left",
}));

export const SponsoredLogoLabelWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
}));

export const CardFooterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(3),
  gap: theme.spacing(4),

  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(2),
  },
}));

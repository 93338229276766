import { AudioPlayerType, AudioType } from "@constants/consts";
import { Track } from "@utils/audio/dataModels";
import TritonAudioPlayer from "./TritonAudioPlayer";
import WebAudioPlayer from "./WebAudioPlayer";

class AudioPlayerManager {
  private webPlayer: WebAudioPlayer;
  private tritonPlayer: TritonAudioPlayer;
  private player: WebAudioPlayer | null = null;
  private eventCallbacks: PlayerEventRegistry<keyof AudioPlayerEventParams> = {};
  public media: AudioStationEntry | Track | null = null;
  public isMuted: boolean = false;
  public volume: number = 1;

  constructor() {
    this.webPlayer = new WebAudioPlayer(this.dispatch);
    this.tritonPlayer = new TritonAudioPlayer(this.dispatch);
  }

  async load(media: AudioStationEntry | Track) {
    if (this.player?.isPlaying) {
      await this.player.stop();
    }

    this.media = media;

    if (media.type === AudioType.STATION) {
      this.setupPlayer(AudioPlayerType.TRITON);
      this.dispatch("audioItem", media);
      this.player?.load(media);
    } else if (media.type === AudioType.TRACK) {
      this.setupPlayer(AudioPlayerType.WEB);
      if (this.tritonPlayer) this.tritonPlayer.cleanUpSubscription();
      if (media.previewUrl) {
        this.start(media.previewUrl);
        this.dispatch("nowPlaying", media);
      }
    }
  }

  async start(source: string): Promise<void> {
    if (this.player?.isPlaying) {
      await this.player.stop();
    }
    this.dispatch("beforePlay", { details: "Attempting to play..." });
    if (this.player) {
      this.player.volume = this.isMuted ? 0 : this.volume;
      this.player.play(source);
    }
  }

  private setupPlayer(playerType: AudioPlayerType): void {
    if (playerType === AudioPlayerType.WEB) {
      this.player = this.webPlayer;
      this.dispatch("playerType", AudioPlayerType.WEB);
    } else {
      this.player = this.tritonPlayer;
      this.dispatch("playerType", AudioPlayerType.TRITON);
    }
  }

  private dispatch = <K extends keyof AudioPlayerEventParams>(key: K, params: AudioPlayerEventParams[K]): void => {
    if (key === "complete" && this.tritonPlayer.currentStation) {
      // load previous station
      this.load(this.tritonPlayer.currentStation);
    }
    const callbacks = (this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[]) || [];

    callbacks.forEach((callback) => {
      callback(params);
    });
  };

  on<K extends keyof AudioPlayerEventParams>(key: K, callback: (params: AudioPlayerEventParams[K]) => void): void {
    if (!this.eventCallbacks[key]) {
      this.eventCallbacks[key] = [];
    }
    const callbacks = this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[];
    if (!callbacks.includes(callback)) {
      callbacks.push(callback);
    }
  }

  off<K extends keyof AudioPlayerEventParams>(key: K, callback: (params: AudioPlayerEventParams[K]) => void): void {
    const callbacks = this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[];
    const index = callbacks?.indexOf(callback);
    if (callbacks && index && index !== -1) {
      callbacks.splice(index, 1);
    }
  }

  pause(): void {
    if (this.player) {
      this.player.pause();
    }
  }

  resume(): void {
    if (this.player) {
      this.player.resume();
    }
  }

  stop(): void {
    if (this.player) {
      this.player.stop();
    }
  }

  complete(): void {
    if (this.player) {
      this.player.complete();
    }
  }

  mute(): void {
    this.isMuted = true;
    if (this.player) {
      this.player.mute();
    }
  }

  unmute(): void {
    this.isMuted = false;
    if (this.player) {
      this.player.unmute();
    }
  }

  volumeChange(volume: number): void {
    if (this.isMuted) this.isMuted = false;
    this.volume = volume;
    if (this.player) {
      this.player.volumeChange(volume);
    }
  }

  getNowPlayingTrack(): Track | null {
    if (!this.player) return null;

    return this.player.nowPlayingTrack;
  }

  getPlaying(): boolean {
    return !!this.player?.isPlaying;
  }
}

const audioPlayerManager = new AudioPlayerManager();

export default audioPlayerManager;

import React, { ReactNode } from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import { styled } from "@mui/material";
import { colors } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { getLinkProps } from "@utils/common/getLinkProps";

const Hyperlink = styled(Link)({
  color: colors.primary,
});

const trackClick = (url: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.ARTICLE,
    event_name: TEALIUM_EVENT_NAME.OUTBOUND_CLICK,
    event_label: url,
  });
};

const RichTextHyperlink: React.FC<{ children: ReactNode; data?: { uri?: string } }> = ({ data, children }) => {
  if (!data?.uri) return null;

  const uri = data.uri;
  const additionalLinkProps = getLinkProps(uri);

  return (
    <Hyperlink href={uri} onClick={() => trackClick(uri)} {...additionalLinkProps}>
      {children}
    </Hyperlink>
  );
};

export default RichTextHyperlink;

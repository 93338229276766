import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { AudioMonitor, HeartBeat } from "@4tn/webx-analytics";
import { AudioPlayerType, heartBeatScheme, radioLiveVideoContainerIds } from "@constants/consts";
import * as Styled from "./AudioPlayer.styled";
import audioPlayerManager from "./AudioPlayerManager/AudioPlayerManager";
import IconSection from "./components/IconSection";
import MainPlayerControls from "./components/MainPlayerControls";
import NowPlaying from "./components/NowPlaying";

const VideoPlayerPortal = dynamic(() => import("@video/VideoPlayerPortal"));

const AudioPlayer: React.FC<{ isHomePage: boolean; mainStation: AudioStationEntry }> = ({
  isHomePage,
  mainStation,
}) => {
  const [status, setStatus] = useState<{ state: AudioPlayerState; details: string }>({
    state: "stop",
    details: "",
  });
  const [inDashboardView, setInDashboardView] = useState(isHomePage);
  const [playerType, setPlayerType] = useState<AudioPlayerEventParams["playerType"]>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    const handlePlay = ({ details }: AudioPlayerEventParams["play"]) => {
      HeartBeat.start();
      window.eventBus.dispatch("radioLiveVideo", { isPlaying: false });
      setStatus({ state: "playing", details });
    };

    const handlePause = ({ details }: AudioPlayerEventParams["pause"]) => {
      HeartBeat.stop();
      setStatus({ state: "pause", details });
    };

    const handleStop = ({ details }: AudioPlayerEventParams["stop"]) => {
      HeartBeat.stop();
      setStatus({ state: "stop", details });
    };

    const handleAudioCompleted = ({ details }: AudioPlayerEventParams["complete"]) => {
      HeartBeat.stop();
      setStatus({ state: "completed", details });
    };

    const handleError = ({ details }: AudioPlayerEventParams["error"]) => {
      HeartBeat.stop();
      setStatus({ state: "failed", details });
    };

    const handlePlayerTypeChange = (playerType: AudioPlayerType) => {
      console.log("audio player type is: ", playerType);
      setPlayerType(playerType);
    };

    const handleAd = ({ details }: AudioPlayerEventParams["adStarted"] | AudioPlayerEventParams["adComplete"]) => {
      setStatus((prevState) => ({ ...prevState, details }));
    };

    const handleBeforePlay = () => {
      setStatus({ state: "loading", details: "Player is loading" });
    };

    const onDashboardInView = (data: WebXEventParams["audioDashboard"]) => setInDashboardView(data.visible);

    AudioMonitor.init({ id: "audioPlayer", instance: audioPlayerManager });
    HeartBeat.init({
      scheme: heartBeatScheme,
      events: {
        onHeartBeat: (event) => {
          const currentMinute = event.currentHeartBeat / 60;
          const allowedIntervals = [1, 8, 15, 30, 60, 90, 120];
          // Send heartbeat if it's one of the milestones or a multiple of 120 minutes
          if (allowedIntervals.includes(currentMinute) || currentMinute % 120 === 0) {
            return { event_value: `${currentMinute}`, event_label: null };
          }

          return false;
        },
      },
    });

    const handleToggleLiveVideo = (data: WebXEventParams["radioLiveVideo"]) => setIsVideoPlaying(data.isPlaying);

    audioPlayerManager.on("play", handlePlay);
    audioPlayerManager.on("pause", handlePause);
    audioPlayerManager.on("adStarted", handleAd);
    audioPlayerManager.on("adComplete", handleAd);
    audioPlayerManager.on("stop", handleStop);
    audioPlayerManager.on("complete", handleAudioCompleted);
    audioPlayerManager.on("error", handleError);
    audioPlayerManager.on("playerType", handlePlayerTypeChange);
    audioPlayerManager.on("beforePlay", handleBeforePlay);
    window.eventBus.on("radioLiveVideo", handleToggleLiveVideo, true);
    window.eventBus.on("audioDashboard", onDashboardInView);

    return () => {
      audioPlayerManager.off("play", handlePlay);
      audioPlayerManager.off("pause", handlePause);
      audioPlayerManager.off("adStarted", handleAd);
      audioPlayerManager.off("adComplete", handleAd);
      audioPlayerManager.off("stop", handleStop);
      audioPlayerManager.on("complete", handleAudioCompleted);
      audioPlayerManager.off("error", handleError);
      audioPlayerManager.off("playerType", handlePlayerTypeChange);
      audioPlayerManager.off("beforePlay", handleBeforePlay);
      window.eventBus.off("radioLiveVideo", handleToggleLiveVideo);
      window.eventBus.off("audioDashboard", onDashboardInView);
      AudioMonitor.unsubscribeFromEvents();
      HeartBeat.stop();
    };
  }, []);

  useEffect(() => {
    if (mainStation && !audioPlayerManager.media) {
      audioPlayerManager.load(mainStation);
    }
  }, [mainStation, isHomePage]);

  if (!mainStation) {
    return null;
  }

  return (
    <Styled.AudioPlayerContainer inDashboardView={!!inDashboardView} id={radioLiveVideoContainerIds.STICKY_PLAYER}>
      <NowPlaying station={mainStation} playerType={playerType} />
      <MainPlayerControls station={mainStation} playbackState={status.state} playerType={playerType} />
      <IconSection
        inAudioDashboard={inDashboardView}
        videoId={mainStation.videoGuid}
        isVideoPlaying={isVideoPlaying}
        playerType={playerType}
      />
      {isVideoPlaying && mainStation.videoGuid && (
        <VideoPlayerPortal
          videoGuid={mainStation.videoGuid}
          containerId={
            inDashboardView ? radioLiveVideoContainerIds.AUDIO_DASHBOARD : radioLiveVideoContainerIds.STICKY_PLAYER
          }
        />
      )}
    </Styled.AudioPlayerContainer>
  );
};

export default AudioPlayer;

import React, { useEffect, useState } from "react";
import { JwMonitor } from "@4tn/webx-analytics";
import { Skeleton, styled } from "@mui/material";
import { useInView } from "react-intersection-observer";
import AspectRatioContainer from "@common/AspectRatioContainer";
import InlinePlayer from "@pageContent/ArticleInlinePlayer";
import SwimlaneItemSkeleton from "@pageContent/common/Skeletons/SwimlaneItemSkeleton";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton";
import { videoCategoryCollectionPageSize as limit } from "@constants/consts";
import usePaginatedFetch from "@utils/common/usePaginatedFetch";
import {
  SectionTitle,
  SwimlaneItems,
  VideoCollectionContainer,
  VideoCollectionWrapper,
  trackVideoItemClick,
} from "../VideoCollection";
import VideoCollectionSwimlaneItem from "../components/VideoCollectionSwimlaneItem";

export const swimlaneObserverTestId = "swimlaneObserverTestId";

const SwimlaneObserver = styled("div")(() => ({
  display: "hidden",
}));

const SwimlaneSkeleton: React.FC<{ swimlaneSkeletonItemCount: number }> = ({ swimlaneSkeletonItemCount }) => (
  <>
    {[...Array(swimlaneSkeletonItemCount)].map((_, i) => (
      <SwimlaneItemSkeleton key={`video-category-collection-${i}`} />
    ))}
  </>
);

export interface VideoCategoryCollectionProps {
  title: string;
  endpoint: string;
  hasSidebarVideoList?: boolean;
  initialData?: Video[];
}

export const VideoCollectionSkeleton: React.FC<{ hideTitle?: boolean }> = ({ hideTitle }) => (
  <VideoCollectionWrapper>
    {!hideTitle && <TextSkeleton height={[24, 32, 32]} width={[180, 240, 240]} />}
    <VideoCollectionContainer>
      <Skeleton variant="rounded" component="div" sx={{ width: "100%", maxWidth: "none" }}>
        <AspectRatioContainer aspectRatio="16:9" />
      </Skeleton>
      <SwimlaneItems scrollToItemIndex={1}>
        <SwimlaneSkeleton swimlaneSkeletonItemCount={limit} />
      </SwimlaneItems>
    </VideoCollectionContainer>
  </VideoCollectionWrapper>
);

const VideoCategoryCollection: React.FC<VideoCategoryCollectionProps> = ({
  title,
  endpoint,
  hasSidebarVideoList = true,
  initialData,
}) => {
  const { ref, inView: isScrollingObserverInView } = useInView();

  const { items, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = usePaginatedFetch<Video>({
    collectionName: "video-category-collection",
    endpoint,
    limit,
    initialData: () => {
      if (initialData) {
        return {
          pageParams: [1],
          pages: [
            {
              items: initialData.slice(0, limit),
              nextPage: 1,
              totalResults: 0,
            },
          ],
        };
      }
      return undefined;
    },
  });

  const [mainVideo, setMainVideo] = useState<Video | null>(items?.length ? items[0] : null);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    if (!isLoading && items?.length) {
      setMainVideo((prevMainVideo) => (prevMainVideo && prevMainVideo?.id !== items[0].id ? prevMainVideo : items[0]));
    }
  }, [isLoading, items]);

  useEffect(() => {
    if (isScrollingObserverInView) fetchNextPage();
  }, [isScrollingObserverInView]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSwimlaneItemClick = (item: Video, videoPosition: number) => {
    JwMonitor.setPlayReason("interaction");
    setMainVideo(item);
    setPlayVideo(true);
    trackVideoItemClick({ ...item, eventLabel: title, videoPosition });
  };

  if (isLoading && (!items || !mainVideo)) {
    return <VideoCollectionSkeleton />;
  }

  if (!isLoading && !items?.length) {
    return null;
  }

  if (items?.length && mainVideo) {
    return (
      <VideoCollectionWrapper>
        <SectionTitle>{title}</SectionTitle>
        <VideoCollectionContainer hasSidebarVideoList={hasSidebarVideoList}>
          <InlinePlayer
            title={mainVideo.title}
            duration={mainVideo.duration}
            imageUrl={mainVideo.imageUrl}
            videoGuid={mainVideo.videoGuid}
            playerLoadedInitially={playVideo}
          />
          <SwimlaneItems scrollToItemIndex={1} hasSidebarVideoList={hasSidebarVideoList}>
            {items.map((item, index) => (
              <VideoCollectionSwimlaneItem<Video>
                key={item.id}
                item={item}
                videoPosition={index + 1}
                isMainVideo={item.id === mainVideo.id}
                onItemClick={onSwimlaneItemClick}
              />
            ))}
            {isFetchingNextPage && <SwimlaneSkeleton swimlaneSkeletonItemCount={limit} />}
            {hasNextPage && <SwimlaneObserver ref={ref} data-testid={swimlaneObserverTestId} />}
          </SwimlaneItems>
        </VideoCollectionContainer>
      </VideoCollectionWrapper>
    );
  }
};

export default VideoCategoryCollection;

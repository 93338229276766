import { useQuery } from "@tanstack/react-query";
import DynamicContent from "@pageContent/DynamicContent";
import InfiniteCollectionSkeleton from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { useNoticeError } from "@utils/common/newRelic";

const errorCollectionQueryKey = "error-collection";
export const fetchNotFoundPageCollection = (url: string): Promise<NotFoundPageType> =>
  fetch(url)
    .then((res) => res.json())
    .then(({ data }) => data);

const ErrorCollection: React.FC<{ collectionUrl: string; limit: number }> = ({ collectionUrl, limit }) => {
  const {
    data: collection,
    error,
    isLoading,
  } = useQuery<NotFoundPageType>({
    queryKey: [errorCollectionQueryKey],
    queryFn: () => fetchNotFoundPageCollection(collectionUrl),
  });

  useNoticeError(error, { queryKey: errorCollectionQueryKey, collectionUrl });

  if (!collection && isLoading) {
    return <InfiniteCollectionSkeleton title={""} limit={limit} showCategory showAllText={""} />;
  }

  if (!collection) return null;

  return <DynamicContent {...collection} />;
};

export default ErrorCollection;

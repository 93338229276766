import React, { ReactNode } from "react";
import { styled } from "@mui/material";
import { RichText } from "@constants/richText";

const UnorderedList = styled("ul")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

const OrderedList = styled("ol")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

const RichTextList: React.FC<{ children: ReactNode; type?: string }> = ({ children, type }) => {
  if (type === RichText.OL_LIST) {
    return <OrderedList>{children}</OrderedList>;
  }

  if (type === RichText.UL_LIST) {
    return <UnorderedList>{children}</UnorderedList>;
  }

  return null;
};

export default RichTextList;

import { FC } from "react";
import InfiniteCollection from "@pageContent/common/InfiniteCollection";
import { useHostConfig } from "@utils/common/getHostConfig";
import useFetchLimit from "@utils/pageContent/useFetchLimit";
import { CategoryBasedCollectionWrapper } from "./CategoryBasedCollection.styled";

const initialFetchedItemsNumber = {
  xs: 5,
  sm: 8,
  md: 6,
  lg: 8,
};

const collectionName = "category-based-collection";

interface CategoryBasedCollectionProps {
  title: string;
  endpoint: string;
  showAllText?: string;
  showAllUrl?: string;
  showCategory: boolean;
  enlargeFirstItem: boolean;
}

const CategoryBasedCollection: FC<CategoryBasedCollectionProps> = (props) => {
  const { designTokens } = useHostConfig();

  const articlesGrid = designTokens?.articleGrid?.itemFetchLimit || initialFetchedItemsNumber;
  const limit = useFetchLimit(articlesGrid);

  return (
    <CategoryBasedCollectionWrapper enlargeFirstItem={props.enlargeFirstItem ?? true}>
      <InfiniteCollection limit={limit} collectionName={collectionName} {...props} />
    </CategoryBasedCollectionWrapper>
  );
};

export default CategoryBasedCollection;

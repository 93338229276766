import { Theme, useMediaQuery } from "@mui/material";

interface FetchedItemNumber {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}

export default function useFetchLimit(fetchItemNumber: FetchedItemNumber) {
  const isExtraSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("xs"));
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("sm"));
  const isMediumScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("md"));

  if (isExtraSmallScreen) return fetchItemNumber.xs;
  if (isSmallScreen) return fetchItemNumber.sm;
  if (isMediumScreen) return fetchItemNumber.md;
  return fetchItemNumber.lg;
}

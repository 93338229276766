import { ReactSVG, Props as SVGProps } from "react-svg";
import { IconEnum } from "@constants/consts";
import Spinner from "./Spinner";

interface IconProps extends Omit<SVGProps, "ref" | "src"> {}

const Icon: React.FC<IconProps & { icon: IconEnum }> = ({ icon, ...props }) => (
  <ReactSVG
    {...props}
    src={`/icons${(typeof window !== "undefined" && window.iconPrefix) || ""}/${icon.replace(/(left|right)_/, "")}.svg`}
    beforeInjection={(svg) => {
      if (icon.startsWith("right_")) svg.setAttribute("style", "rotate:180deg");
    }}
  />
);

export default Icon;

// All the icons that aren't exclusively used in the form of the icon buttons are exported below
export const QuoteIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.QUOTE} />;
export const OptionsIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.OPTIONS} />;

export const HamburgerIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.HAMBURGER} />;
export const SearchIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.SEARCH} />;
export const PlayIconFilled: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PLAY_ICON_FILLED} />;
export const LeftChevronIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.LEFT_CHEVRON} />;
export const RightChevronIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.RIGHT_CHEVRON} />;
export const LeftBoldChevronIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} icon={IconEnum.LEFT_BOLD_CHEVRON} />
);
export const RightBoldChevronIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} icon={IconEnum.RIGHT_BOLD_CHEVRON} />
);
export const LeftDoubleChevronIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} icon={IconEnum.LEFT_DOUBLE_CHEVRON} />
);
export const RightDoubleChevronIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} icon={IconEnum.RIGHT_DOUBLE_CHEVRON} />
);
export const LeftArrowIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.LEFT_ARROW} />;
export const RightArrowIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.RIGHT_ARROW} />;
export const AnimatedArrowIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.ANIMATED_ARROW} />;
export const CrossIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.CROSS} />;
export const AirplayIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.AIRPLAY} />;
export const CallIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.CALL} />;
export const ChatIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.CHAT} />;
export const CheckIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.CHECK} />;
export const ChromecastIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.CHROMECAST} />;
export const ClosedCaptionIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.CLOSED_CAPTION} />;
export const FilterIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.FILTER} />;
export const FullscreenIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.FULLSCREEN} />;
export const FullscreenExitIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.FULLSCREEN_EXIT} />;
export const HeartIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.HEART} />;
export const HomeIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.HOME} />;
export const IdCardIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.ID_CARD} />;
export const InfoIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.INFO} />;
export const PauseIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PAUSE} />;
export const PencilIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PENCIL} />;
export const PlayOutlinedIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PLAY_OUTLINED} />;
export const PlayOutlinedFilledIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} icon={IconEnum.PLAY_OUTLINED_FILLED} />
);
export const PlaybackSpeedIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PLAYBACK_SPEED} />;
export const PlusIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PLUS} />;
export const PopoutIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.POPOUT} />;
export const PopoutPlayerIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.POPOUT_PLAYER} />;
export const ProfileIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.PROFILE} />;
export const LockIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.LOCK} />;
export const SettingsIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.SETTINGS} />;
export const ShareIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.SHARE} />;
export const StopOutlinedIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.STOP_OUTLINED} />;
export const StopFilledIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.STOP_FILLED} />;
export const TimeIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.TIME} />;
export const LinkIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.LINK} />;
export const VolumeMaxIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.VOLUME_MAX} />;
export const VolumeMinIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.VOLUME_MIN} />;
export const VolumeOffIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.VOLUME_OFF} />;
export const DownArrowIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.ARROW_DOWN} />;
export const UpArrowIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.ARROW_UP} />;
export const DownChevronIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.DOWN_CHEVRON} />;
export const UpChevronIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.UP_CHEVRON} />;
export const Logout: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.LOGOUT} />;
export const StationsIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.STATIONS} />;
export const VideoIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.VIDEO} />;
export const VideoIconFilled: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.VIDEO_FILLED} />;

export const TalpaNetworkIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.TALPA_NETWORK} />;
export const FigmaIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.FIGMA} />;
export const MailIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.MAIL} />;
export const InstagramIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.INSTAGRAM} />;
export const WhatsAppIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.WHATSAPP} />;
export const FacebookIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.FACEBOOK} />;
export const TikTokIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.TIK_TOK} />;
export const TwitterIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.TWITTER} />;
export const SpotifyIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.SPOTIFY} />;
export const YoutubeIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.YOUTUBE} />;

export const WebIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.WEB} />;
export const AppsIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.APPS} />;
export const AndroidIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.ANDROID} />;
export const IOSIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={IconEnum.IOS} />;

export const SpinnerIcon: React.FC = () => <Spinner />;

import { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import ErrorBoundary from "@common/ErrorBoundary/ErrorBoundary";
import { HtmlEmbedNode } from "@typings/richText";

const ShadowDomContainer = styled("div")(() => ({
  transform: "scale(1)",

  ["::part(youtube-embed)"]: {
    maxWidth: "100%",

    "@supports (aspect-ratio: 16 / 9)": {
      aspectRatio: "16 / 9",
      width: "100%",
      height: "auto",
      maxWidth: "none",
    },
  },
}));

const HtmlEmbed: React.FC<HtmlEmbedNode["data"]> = ({ html }) => {
  const shadowRootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shadowRootRef.current) {
      const shadowRoot = shadowRootRef.current.shadowRoot || shadowRootRef.current.attachShadow({ mode: "open" });
      shadowRoot.innerHTML = html;
    }
  }, [html]);

  return <ShadowDomContainer ref={shadowRootRef} />;
};

const SafeHtmlEmbed: React.FC<HtmlEmbedNode["data"]> = (props) => (
  <ErrorBoundary onError={() => <></>}>
    <HtmlEmbed {...props} />
  </ErrorBoundary>
);

export default SafeHtmlEmbed;

import Image from "next/image";
import { styled } from "@mui/material/styles";
import { ButtonContainer } from "@common/Buttons";
import { RichTextWrapper } from "@pageContent/RichText/RichText.styled";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const HighlightedItemContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  borderRadius: 6,
  // This makes the borderRadius attribute work for the container
  overflow: "hidden",
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
    margin: theme.spacing(10, 0),
  },

  [theme.breakpoints.up("lg")]: {
    flexDirection: "row-reverse",
  },
}));

export const HighlightedItemImageContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: "66.66%",
  },
}));

export const HighlightedItemImageGradient = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("lg")]: {
    display: "inline",
    position: "absolute",
    width: 130,
    height: "100%",
    background: gradients.fadePrimaryRight,
    // This makes the gradient appear above the image
    zIndex: zIndex.base,
  },
}));

export const HighlightedItemImage = styled(Image)(() => ({
  objectFit: "cover",
}));

export const HighlightedItemMaskWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: gradients.gradient03,

  [theme.breakpoints.up("lg")]: {
    width: "33.33%",
  },
}));

export const HighlightedItemMask = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: theme.spacing(4, 4, 8),
  gap: theme.spacing(6),

  [`${ButtonContainer}`]: {
    pointerEvents: "none",

    height: 40,
    border: `2px solid ${colors.neutral0}`,
    p: {
      font: fonts.buttonMedium,
      color: colors.neutral0,
    },

    [theme.breakpoints.up("md")]: {
      p: {
        font: fonts.buttonLarge,
      },
      width: 250,
      height: 52,
    },
  },

  [`${RichTextWrapper} > p`]: {
    font: fonts.bodyMedium,
    color: colors.neutral10,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 6,

    [theme.breakpoints.up("sm")]: {
      WebkitLineClamp: 4,
    },

    [theme.breakpoints.up("md")]: {
      WebkitLineClamp: 3,
    },

    [theme.breakpoints.up("xl")]: {
      WebkitLineClamp: 5,
    },
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6, 8, 10),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 0, 10, 10),
  },
}));

export const HighlightedItemTitle = styled("p")(({ theme }) => ({
  font: fonts.header3,
  color: colors.neutral0,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  WebkitLineClamp: 3,

  [theme.breakpoints.up("sm")]: {
    WebkitLineClamp: 2,
  },

  [theme.breakpoints.up("lg")]: {
    WebkitLineClamp: 3,
  },

  [theme.breakpoints.up("xl")]: {
    WebkitLineClamp: 4,
  },
}));

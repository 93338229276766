import { FC, useEffect } from "react";
import Tealium from "@4tn/webx-analytics";
import useSearchValues from "@navigation/SearchModal/useSearchValues";
import OopsPage from "@pageContent/OopsPage";
import { RESULTS_FOR } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import usePaginatedFetch from "@utils/common/usePaginatedFetch";
import { fetchLinks } from "@utils/pageContent/fetchData";
import NoSearchResult from "../NoSearchResult";
import SearchModalPlaceholder from "../SearchModalPlaceholder";
import { searchTabValues } from "../SearchModalTabBar/SearchModalTabBar";
import * as Styled from "./SearchModalResult.styled";
import SearchResultItems from "./SearchResultItems";

const trackClick = (position: number) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.SEARCH_CLICK,
    event_category: TEALIUM_EVENT_CATEGORY.SEARCH,
    event_label: TEALIUM_EVENT_LABEL.SEARCH_RESULTS,
    event_value: position,
  });
};

export const searchLimit = 24;

const SearchModalResult: FC = () => {
  const { searchValue, tabValue } = useSearchValues();
  const tabContentType = searchTabValues[tabValue].contentType;
  const { contentApiEndpoint } = useHostConfig();
  const { data, items, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    usePaginatedFetch<PageLink>({
      queryKey: ["search", searchValue, tabContentType],
      queryFn: async ({ pageParam = 1 }) => {
        if (!searchValue) {
          return {
            items: [],
            nextPage: null,
            totalResults: 0,
          };
        }
        const result = await fetchLinks({
          endpoint: `${contentApiEndpoint}/search/` + searchValue,
          page: pageParam,
          contentType: tabContentType,
          limit: searchLimit,
        });

        if (pageParam === 1) {
          // We don't want loading more search results to register as a separate search query
          Tealium.setDataLayer({
            search_keyword: searchValue,
            search_results: result.totalResults,
          });
          Tealium.link({
            event_name: TEALIUM_EVENT_NAME.SEARCH_QUERY,
            event_category: TEALIUM_EVENT_CATEGORY.SEARCH,
            event_label: searchValue,
          });
        }

        return result;
      },
    });

  const [firstPage] = data?.pages || [];
  useEffect(() => {
    window.eventBus.dispatch("searchResults", { hasResults: !!items?.length });
  }, [items]);

  const title = `${Number(firstPage?.totalResults) > 100 ? "100+" : firstPage?.totalResults} ${RESULTS_FOR} `;

  if (isLoading && !firstPage?.items.length)
    return (
      <Styled.InfiniteCollectionSkeletonContainer title={title} limit={searchLimit} showCategory showAllText={""} />
    );

  if (isError) return <OopsPage />;
  if (!searchValue) return <SearchModalPlaceholder />;

  if (searchValue && firstPage?.items.length === 0 && !isLoading) return <NoSearchResult currentTab={tabValue} />;

  return (
    <SearchResultItems
      title={title}
      searchValue={searchValue}
      items={items}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={!!hasNextPage}
      isLoading={isLoading}
      limit={searchLimit}
      fetchNextPage={fetchNextPage}
      trackClick={trackClick}
    />
  );
};

export default SearchModalResult;

const linearEasing = (t: number, b: number, c: number, d: number): number => {
  return (c * t) / d + b;
};

export default function smoothScrollTo(element: Element, targetPosition: number, duration: number = 300): void {
  const startPosition: number = element.scrollLeft;
  if (startPosition === targetPosition) return;
  if (duration === 0) return element.scrollTo(targetPosition, 0);

  const distance: number = targetPosition - startPosition;
  let startTime: number | null = null;

  function animation(currentTime: number): void {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const nextScrollPosition = linearEasing(timeElapsed, startPosition, distance, duration);
    element.scrollTo(nextScrollPosition, 0);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    } else {
      element.scrollTo(targetPosition, 0);
    }
  }

  requestAnimationFrame(animation);
}
